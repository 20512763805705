import React, { useState } from 'react'
import { DateRange } from 'react-date-range';
import moment from 'moment';
import { Menu } from '@mui/material';
import { DateRange as DateIcon } from '@mui/icons-material'

import './DatePicker.css'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function DatePicker(props) {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleChange = (dates) => {
        props.onChange({
            startDate: dates.selection.startDate,
            endDate: dates.selection.endDate,
            key: 'selection',
        })
    }

    return (
        <div>
            <div className={`date-picker-input ${props.value.startDate ? 'filled' : 'empty'}`} onClick={(e) => setAnchorEl(e.currentTarget)}>
                {props.value.startDate ?
                    `${moment(props.value.startDate).format('MMM DD, YYYY')} - ${moment(props.value.endDate).format('MMM DD, YYYY')}`
                    :
                    <div className='date-picker-input-text'>
                        <p>Date</p>
                        <DateIcon sx={{ marginBottom: '3px' }} />
                    </div>
                }
                <div className='date-picker-input-inner' />
            </div>
            <Menu
                id='profile-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <DateRange
                    ranges={[props.value]}
                    onChange={handleChange}
                    rangeColors={['var(--pink)']}
                    showDateDisplay={false}
                    className='date-picker-wrapper'
                />
            </Menu>
        </div>
    )
}

export default DatePicker