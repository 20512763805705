import React, { useRef, useState, useEffect } from 'react'
import { Divider, Chip } from '@mui/material'
import { DropDownFilter } from './DropDownFilter';
import SearchInput from '../Inputs/SearchInput';
import { useDebounce } from '../../utils';
import moment from 'moment';

import './EventFilters.css'
import DatePicker from '../Inputs/DatePicker';
import { getFilterData } from '../../API/requests';
import { FilterAlt } from '@mui/icons-material';

function EventFilters(props) {

    const formatFilterRef = useRef();
    const topicFilterRef = useRef();
    const typeFilterRef = useRef();
    const industryFilterRef = useRef();

    const [formatFilter, setFormatFilter] = useState([])
    const [topicFilter, setTopicFilter] = useState([])
    const [typeFilter, setTypeFilter] = useState([])
    const [industryFilter, setIndustryFilter] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [dateFilter, setDateFilter] = useState({
        startDate: null,
        endDate: new Date(""), //Workaround visto que o calendario não suporta nulls
        key: 'selection'
    })
    const debounceSearch = useDebounce(searchValue, 500)

    const [formatData, setFormatData] = useState([])
    const [topicsData, setTopicsData] = useState([])
    const [typeData, setTypeData] = useState([])
    const [industriesData, setIndustriesData] = useState([])

    const [showFilter, setShowFilter] = useState(false)

    useEffect(() => {
        getFilterData('events').then(res => {
            setFormatData(res.data.formats)
            setIndustriesData(res.data.industries)
            setTopicsData(res.data.topics)
            setTypeData(res.data.types)
        }, err => {
            console.log(err)
        })
    }, [])

    const filterResults = (value, field) => {
        switch (field) {
            case 'Format': setFormatFilter(value);
                break;
            case 'Topic': setTopicFilter(value);
                break;
            case 'Type': setTypeFilter(value);
                break;
            case 'Industry': setIndustryFilter(value);
                break;
            default:
                break;
        }
    }

    const filterLogic = () => {

        if (!formatFilter.length && !topicFilter.length && !typeFilter.length && !searchValue.length && !dateFilter.startDate && !industryFilter.length) {
            props.updateParentState(props.data)
            return;
        }

        const formatResults = props.data.filter(elem => (
            formatFilter.some(format => elem.formats.find(el => el.id === format.id))
        ))

        const trackResults = props.data.filter(elem => (
            topicFilter.some(topic => elem.topics.find(el => el.id === topic.id))
        ))

        const typeResults = props.data.filter(elem => (
            typeFilter.some(type => elem.types.find(el => el.id === type.id))
        ))

        const industryResults = props.data.filter(elem => (
            industryFilter.some(ind => elem.industries.find(el => el.id === ind.id))
        ))

        const searchResults = props.data.filter(elem => {
            if (searchValue.length === 0) return false
            return (
                elem.title.toLowerCase().includes(searchValue.toLowerCase()) ||
                elem.location?.toLowerCase().includes(searchValue.toLowerCase()) ||
                elem.series?.title.toLowerCase().includes(searchValue.toLowerCase())

            )
        })

        const dateResults = props.data.filter(elem =>
            moment(elem.start_date).isBetween(moment(dateFilter.startDate), moment(dateFilter.endDate), 'day', '[]')
        )

        props.updateParentState([...new Set([...formatResults, ...trackResults, ...typeResults, ...searchResults, ...dateResults, ...industryResults])])

    }

    const deleteFilterEntry = (value, field) => {
        switch (field) {
            case 'format': formatFilterRef.current.deleteOnChild(value);
                break;
            case 'topic': topicFilterRef.current.deleteOnChild(value);
                break;
            case 'type': typeFilterRef.current.deleteOnChild(value);
                break;
            case 'industry': industryFilterRef.current.deleteOnChild(value);
                break;
            default:
                break;
        }
    }

    const removeAllFromChild = () => {
        formatFilterRef.current.removeAll();
        topicFilterRef.current.removeAll();
        typeFilterRef.current.removeAll();
        industryFilterRef.current.removeAll()

        setDateFilter({
            startDate: null,
            endDate: new Date(""),
            key: 'selection'
        })
    }

    useEffect(() => {
        if (props.filteredData) {
            const id = props.filteredData.value.id
            const value = props.filteredData.value.name
            const property = props.filteredData.property
            filterResults([{ id, value }], property)
        }
    }, [props.filteredData])

    useEffect(() => {
        filterLogic()
    }, [formatFilter, topicFilter, typeFilter, debounceSearch, dateFilter, industryFilter])

    return (
        <div className={`event-filter-main-div ${showFilter ? 'show' : 'hide'}`}>
            <div className='event-filters'>
                <SearchInput value={searchValue} clear={() => setSearchValue('')} onChange={(e) => setSearchValue(e.target.value)} />
                <div className='event-filters-wrapper'>
                    <DropDownFilter ref={formatFilterRef} filter={filterResults} data={formatData} label='Format' placeholder='Format' isDataObject={true} />
                    <DropDownFilter ref={typeFilterRef} filter={filterResults} data={typeData} label='Type' placeholder='Type' isDataObject={true} />
                    <DropDownFilter ref={industryFilterRef} filter={filterResults} data={industriesData} label='Industry' placeholder='Industry' isDataObject={true} />
                    <DropDownFilter ref={topicFilterRef} filter={filterResults} data={topicsData} label='Topic' placeholder='Topic' isDataObject={true} />
                    <DatePicker value={dateFilter} onChange={date => setDateFilter(date)} />
                </div>
            </div>
            <div className='event-filtered-chips'>
                <div className='event-all-chips'>
                    {topicFilter.map(topic => (
                        <Chip key={topic.id} color='secondary' variant='outlined' label={topic.value} onDelete={() => deleteFilterEntry(topic, 'topic')} />
                    ))}
                    {topicFilter.length ? <Divider orientation='vertical' /> : ''}
                    {formatFilter.map(format => (
                        <Chip key={format.id} color='secondary' variant='outlined' label={format.value} onDelete={() => deleteFilterEntry(format, 'format')} />
                    ))}
                    {formatFilter.length ? <Divider orientation='vertical' /> : ''}
                    {typeFilter.map(type => (
                        <Chip key={type.id} color='secondary' variant='outlined' label={type.value} onDelete={() => deleteFilterEntry(type, 'type')} />
                    ))}
                    {typeFilter.length ? <Divider orientation='vertical' /> : ''}
                    {industryFilter.map(indus => (
                        <Chip key={indus.id} color='secondary' variant='outlined' label={indus.value} onDelete={() => deleteFilterEntry(indus, 'industry')} />
                    ))}
                    {industryFilter.length ? <Divider orientation='vertical' /> : ''}
                    {dateFilter.startDate &&
                        <Chip color='secondary' variant='outlined' label={`${moment(dateFilter.startDate).format('MMM DD, YYYY')} - ${moment(dateFilter.endDate).format('MMM DD, YYYY')}`} onDelete={() => setDateFilter({ startDate: null, endDate: new Date(""), key: 'selection' })} />
                    }
                    {dateFilter.length ? <Divider orientation='vertical' /> : ''}
                    <div className='event-clear-all-chip'>
                        {topicFilter.length || formatFilter.length || typeFilter.length || industryFilter.length ? (
                            <Chip label='Clear All' onDelete={removeAllFromChild} />
                        ) : ''}
                    </div>
                </div>
            </div>
            <div className={`filters-show-button${showFilter ? ' active' : ''}`}>
                <FilterAlt />
                <p onClick={() => setShowFilter(!showFilter)}>{`${showFilter ? 'Hide' : 'Show'} Filters`}</p>
            </div>
        </div>
    )
}

export default EventFilters
