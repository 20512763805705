import { useEffect } from 'react'
import { useLocation } from 'react-router'
import ReactGA from 'react-ga4'

const pageName = ['/', '/episodes', '/calendar', '/about', '/help', '/event',]

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize('G-6GXDZMHBMB', { gaOptions: { cookieFlags: "SameSite=None;Secure" } });
        loadMunchkinScript()

        if (pageName.includes(location.pathname)) {
            ReactGA.send({ hitType: 'pageview', page: location.pathname })
            if (window.Munchkin) {
                window.Munchkin.munchkinFunction('visitWebPage', { 'url': location.pathname })
            }
        }

    }, [location])
}

const loadMunchkinScript = () => {
    let didInit = false;
    function initMunchkin() {
        if (didInit === false) {
            didInit = true;
            window.Munchkin.init('195-OTS-308');
        }
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//munchkin.marketo.net/munchkin.js';
    script.onreadystatechange = function () {
        if (this.readyState == 'complete' || this.readyState == 'loaded') {
            initMunchkin();
        }
    };
    script.onload = initMunchkin;
    document.getElementsByTagName('head')[0].appendChild(script);
}

export default usePageTracking