import React from 'react'
import { useNavigate } from 'react-router-dom'
import { stringToColor } from '../../utils'
import { Custom } from '../Buttons/PrefabButtons'
import './TopicCard.css'

function TopicCard(props) {
    const navigate = useNavigate()

    return (
        <div className='topic-event-card-main-div' style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/topics/${props.topic.image}` }} >
            <div className='topic-event-card-black-div' style={{ background: `linear-gradient(0deg, ${stringToColor(props.topic.name)} 0%, rgba(34, 26, 42, 0) 75%)` }} />
            <div className='topic-event-card-inner-div'>
                <div className='topic-event-card-info'>
                    <p className='topic-card-info-title'>{props.topic.name}</p>
                    <p style={{ fontSize: '14px', fontWeight: '400', color: 'white' }}>{`${props.topic.episodes} episode${props.topic.episodes === 1 ? '':'s'}`}</p>
                </div>
                <div className='topic-event-card-button'>
                    <Custom
                        onClick={() => navigate(`/episodes`, { state: { state: { Topic: { id: props.topic.id, value: props.topic.name } } } })}
                        size='fitting-small'
                    >
                        View episodes
                    </Custom>
                </div>
            </div>
        </div>
    )
}

export default TopicCard