import axios from 'axios'

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const key = process.env.REACT_APP_API_KEY;

const getHeaders = async () => {
    return {
        "Authorization": key,
        "requesting-user": localStorage.getItem('user_id') ? `fo_${localStorage.getItem('user_id')}` : 'fo_0',
    }
}

export const authUser = async (email, code) => {
    return await axios.post(`${SERVER_URL}/api/app_users/auth`, { email, code })
}

export const loginUserMarketo = async (email) => {
    return await axios.post(`${SERVER_URL}/api/mrktologin/${email}`)
}

export const forgotPass = async (email) => {
    return await axios.post(`${SERVER_URL}/api/users/passresetrequest`, { email })
}

export const getEntity = async (entity, id, data) => {
    return await axios.get(`${SERVER_URL}/api/${entity}${id ? `/${id}` : ''}`, { headers: await getHeaders(), params: data })
}

export const getCalendarData = async () => {
    return await axios.get(`${SERVER_URL}/api/calendar-data`, { headers: await getHeaders() })
}

export const trackViewReq = async (data) => {
    return await axios.post(`${SERVER_URL}/api/createview`, data, { headers: await getHeaders() })
}

export const sendContactEmail = async (data) => {
    return await axios.post(`${SERVER_URL}/api/sendContactEmail`, data, { headers: await getHeaders() })
}

export const marketoVerify = async (data) => {
    return await axios.post(`${SERVER_URL}/api/mrktoVerify/`, data)
}

export const verifyUser = async (remember_token, id) => {
    return await axios.post(`${SERVER_URL}/api/app_users/verify/${id}`, { remember_token }, { headers: await getHeaders() })
}

export const getFeaturedEpisodes = async () => {
    return await axios.get(`${SERVER_URL}/api/featured/episodes`, { headers: await getHeaders() })
}

export const getSuggestedEpisodes = async (data) => {
    return await axios.post(`${SERVER_URL}/api/suggested/episodes`, data, { headers: await getHeaders() })
}

export const getFilterData = async (entity) => {
    return await axios.get(`${SERVER_URL}/api/filter-data/${entity}`, { headers: await getHeaders() })
}

export const getPrivateData = async (entity, id) => {
    return await axios.get(`${SERVER_URL}/api/private-data/${entity}/${id}`, { headers: await getHeaders() })
}

export const resendCode = async (email) => {
    return await axios.post(`${SERVER_URL}/api/app_users/resend-code`, { email }, { headers: await getHeaders() })
}