import React, { useRef, useState } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import { Close } from '@mui/icons-material'
import OTPInput from '../Inputs/OTPInput'
import { authUser, resendCode } from '../../API/requests'
import { UserContext } from '../contexts/User'
import { logoutUser } from '../UserInfo/UserLog'
import Confirmation from '../Dialogs/Confirmation'
import { ThreeDots } from 'react-loading-icons'

import './UserAuth.css'

function UserAuth(props) {
    const hiddenRef = useRef(null)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationDialogData, setConfirmationDialogData] = useState({ error: false, title: '', text: '', buttonLabel: 'OK' })
    const [userState, dispatch] = React.useContext(UserContext);
    const [loading, setLoading] = useState(false)
    const email = localStorage.getItem('email') || sessionStorage.getItem('email')

    const onComplete = value => {
        hiddenRef.current.focus()
        if (email) {
                authUser(email, value).then(res => {
                localStorage.setItem('remember_token', res.headers.remember_token);
                localStorage.setItem('user_id', res.headers.id)
                dispatch({ type: 'auth_user' })
            }, err => {
                console.log(err)
                setConfirmationDialogData({ error: true, title: `Opps!`, text: `Wrong access code. You have entered a wrong code, please try again.`, buttonLabel: 'ok' })
                setShowConfirmation(true)
            })
        } else {
            logoutUser()
            dispatch({ type: 'logout_user' });
            props.close()
        }
    }

    const requestNewCode = () => {
        setLoading(true)
        const email = localStorage.getItem('email') || sessionStorage.getItem('email');
        if (email) {
            resendCode(email).then(res => {
                console.log(res.data)
                setConfirmationDialogData({ error: false, title: `Success`, text: 'A new code was sent to your email address.' })
                setShowConfirmation(true)
                setLoading(false)
            }, err => {
                console.log(err.response)
                setConfirmationDialogData({ error: true, title: `Opps!`, text: err.response?.data ?? 'An error has occurred, please try again', buttonLabel: 'ok' })
                setShowConfirmation(true)
                setLoading(false)
            })
        } else {
            setConfirmationDialogData({ error: true, title: `Opps!`, text: 'Please login so you can request a new code', buttonLabel: 'ok' })
            setShowConfirmation(true)
            setLoading(false)
        }
    }

    return (
        <Dialog
            fullScreen={window.innerWidth < 960}
            maxWidth='md'
            open={props.open}
            onClose={props.close}
            className='event-dialog'
        >
            <DialogContent className='user-auth-dialog-content' >
                <Close className='event-dialog-close-icon' onClick={props.close} />
                <div className='user-auth-text'>
                    <p>Hello, <span style={{ fontWeight: '700' }}>{email}</span></p>
                    <p>Please insert your access code to access this information. This information will be remembered for future visits.</p>
                </div>
                <div className='user-auth-code-div'>
                    <OTPInput handleOnComplete={onComplete} />
                </div>
                <input className='hidden-input' ref={hiddenRef} />
                {loading ?
                    <ThreeDots fill='var(--orange)' style={{ marginTop: '20px' }} />
                    :
                    <p onClick={requestNewCode} className='user-auth-new-code'>Forgot you code? click here to receive a new one</p>
                }
            </DialogContent>
            <Confirmation {...confirmationDialogData} open={showConfirmation} close={() => setShowConfirmation(false)} />
        </Dialog>
    )
}

export default UserAuth