import React, { useState, useEffect, useRef } from 'react'
import { debounce, openInNewTab } from '../utils'
import { UserContext } from '../components/contexts/User'
import { useNavigate } from 'react-router-dom'
import LoginSignupMainDialog from '../components/Login-signup/LoginSignupMainDialog'
import { Backdrop, Tooltip } from '@mui/material'
import { ThreeDots } from 'react-loading-icons'
import sortArray from 'sort-array'
import { ToExternalPage, PrivateButton } from '../components/Buttons/PrefabButtons'
import EpisodeCard from '../components/Events/EpisodeCard'
import MaybeYouLikeSection from '../components/MaybeYouLike/MaybeYouLikeSection'
import SpeakersCard from '../components/Events/SpeakersCard'
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb'
import arrowDown from '../assets/icons/arrow-down.svg'
import { trackView } from '../utils'
import { getEntity } from '../API/requests'
import { getUserName } from '../components/UserInfo/UserLog'
import { mapDistinctArrayOfObjects } from '../utils'
import { Place, CalendarToday, Translate } from '@mui/icons-material'
import PrivateInfoDialog from '../components/Dialogs/PrivateInfoDialog'
import UserAuth from '../components/Login-signup/UserAuth'
import moment from 'moment'

import './css/event.css'


function Event() {
    const [event, setEvent] = useState()
    const [episodes, setEpisodes] = useState([])
    const [speakers, setSpeakers] = useState([])
    const [expandEpisodes, setExpandEpisodes] = useState(false);
    const [expandSpeakers, setExpandSpeakers] = useState(false);
    const [speakersCardHeight, setSpeakersCardHeight] = useState()
    const [episodesCardHeight, setEpisodesCardHeight] = useState()
    const [userState, dispatch] = React.useContext(UserContext)
    const [loginDialog, setLoginDialog] = useState(false);
    const [eventTopics, setEventTopics] = useState([])
    const [eventIndustries, setEventIndustries] = useState([])
    const [eventTypes, setEventTypes] = useState([])
    const [eventFormats, setEventFormats] = useState([])

    const [openUserAuth, setOpenUserAuth] = useState(false)
    const [openPrivate, setOpenPrivate] = useState(false)
    const [userHasAccess, setUserHasAccess] = useState(false)

    const navigate = useNavigate()
    const mainContainerRef = useRef()
    const urlParams = new URLSearchParams(window.location.search)

    useEffect(() => {

        if (urlParams.get('id')) {
            getEntity('events', urlParams.get('id')).then(res => {
                setEvent(res.data)
                setEpisodes(res.data.episodes)
                setSpeakers(mapDistinctArrayOfObjects(res.data.episodes, 'speakers'))
                trackView('event', res.data.id, getUserName())

                if (res.data.has_episodes) {
                    let topics = []
                    let industries = []
                    let formats = []
                    let types = []

                    if (res.data.episodes.length > 0) {
                        res.data.episodes.forEach(el => {
                            topics = [...topics, ...el.topics]
                            industries = [...industries, ...el.industries]
                            formats = [...formats, ...el.formats]
                            types = [...types, ...el.types]
                        });
                    }

                    setEventTopics(getUniqueBy(topics, 'id'))
                    setEventIndustries(getUniqueBy(industries, 'id'))
                    setEventFormats(getUniqueBy(formats, 'id'))
                    setEventTypes(getUniqueBy(types, 'id'))
                }

            }, err => {
                console.log(err)
                navigate(-1)
            })
        } else {
            navigate(-1)
        }
    }, [])

    const getUniqueBy = (arr, prop) => {
        const set = new Set();
        return arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));
    };

    useEffect(() => {
        if (event) {
            if (!userState.logged && event.private) {
                setLoginDialog(true)
            } else {
                setLoginDialog(false)
            }
        }
    }, [userState, event])

    const getEpisodeCardHeight = (width) => {
        if (width < 1287) return 310 * episodes.length;
        return 360 * episodes.length / 2
    }

    const getSpeakersCardHeight = (width) => {
        if (width < 1482 && width > 960) return 125 * speakers.length;
        if (width <= 960) return 110 * speakers.length + 25;
        return 135 * speakers.length / 2
    }

    const embedParams = () => {
        return `&defaultQuality=fullhd&ambient=1`
    }

    useEffect(() => {
        const debouncedHanldeRezise = debounce(() => {
            setSpeakersCardHeight(getSpeakersCardHeight(window.innerWidth))
            setEpisodesCardHeight(getEpisodeCardHeight(window.innerWidth))
            setExpandEpisodes(false);
            setExpandSpeakers(false)
        }, 500)

        window.addEventListener('resize', debouncedHanldeRezise);

        setSpeakersCardHeight(getSpeakersCardHeight(window.innerWidth))
        setEpisodesCardHeight(getEpisodeCardHeight(window.innerWidth))

        return _ => {
            window.removeEventListener('resize', debouncedHanldeRezise)
        }
    })

    useEffect(() => {
        setUserHasAccess(userState.logged && userState.access)

        if (openUserAuth === true) {
            onViewPrivateInfo()
        }
    }, [userState])

    const onViewPrivateInfo = () => {

        if (userState.auth) {
            setOpenPrivate(true)
            setOpenUserAuth(false)
        } else {
            setOpenUserAuth(true)
        }
    }

    return (
        event ? (
            <div ref={mainContainerRef} className='eventpage-main-div'>
                <Breadcrumb serie={event.series} event={event} entity={'event'} />
                {event.trailer.length > 0 ? (
                    <>
                        <div className='video-iframe-trailer test'>
                            <div className='trailer-top-div'>
                                <p className='top-video-title-event'>{event.title}</p>
                                <p className='top-video-subtitle-event'>{event.subtitle}</p>
                            </div>
                            <iframe
                                src={event.trailer + embedParams()}
                                className='iframe test'
                                title='event-trailer-iframe'
                                frameBorder="0"
                                border="0"
                                scrolling="no"
                                mozallowfullscreen="0"
                                webkitallowfullscreen="0"
                                allowFullScreen="0"
                                allow="autoplay">
                            </iframe>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='event-page-top-image'>
                            <img alt='promoted-event' className='top-image' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/events/${event.landscape_image}`} />
                            <div className='top-video-black-div'></div>
                            <div className='top-video-static-black-div'></div>
                            <div className='top-video-info'>
                                <p className='top-video-title'>{event.title}</p>
                                <p className='top-video-subtitle'>{event.subtitle}</p>
                            </div>
                        </div>
                    </>
                )}
                <div className='event-page-bottom-section'>
                    <div className='bottom-section-info'>
                        <div className='bottom-section-text'>
                            <p className='text-title'>{event.title}</p>

                            <div className='event-page-amenities-div'>
                                <div className='sub-info-item'>
                                    <CalendarToday sx={{ scale: '0.9' }} />
                                    <p>{moment(event.start_date).format('MMM DD, YYYY')} - {moment(event.end_date).format('MMM DD, YYYY')}</p>
                                </div>
                                {event.location &&
                                    <div className='sub-info-item'>
                                        <Place />
                                        <p>{event.location?.length > 0 ? event.location : 'Online'}</p>
                                    </div>
                                }
                                {event.language &&
                                    <Tooltip title='Event Language' disableInteractive={true} >
                                        <div className='sub-info-item'>
                                            <Translate />
                                            <p>{event.language}</p>
                                        </div>
                                    </Tooltip>
                                }
                            </div>
                            <div className='event-page-chips-div'>
                                <EventChip name='Format' array={event.formats.length > 0 ? event.formats : eventFormats} />
                                <EventChip name='Type' array={event.types.length > 0 ? event.types : eventTypes} />
                                <EventChip name='Industry' array={event.industries.length > 0 ? event.industries : eventIndustries} />
                                <EventChip name='Topic' array={event.topics.length > 0 ? event.topics : eventTopics} />
                            </div>

                            <p className='text-description'>{event.description}</p>
                            <div className='event-page-actions-div'>
                                {event.event_page !== '' && <ToExternalPage size='fitting-medium' url={event.event_page}>Go to the event website</ToExternalPage>}
                                {userHasAccess &&
                                    <PrivateButton size='fitting-medium' onClick={onViewPrivateInfo}>View Private Info</PrivateButton>
                                }
                            </div>
                        </div>
                        {speakers.length > 0 &&
                            <div className='bottom-section-speakers' style={expandSpeakers ? { maxHeight: speakersCardHeight + 'px' } : { maxHeight: '645px' }}>
                                <p className='speakers-title'>Meet our speakers</p>
                                <div className='speakers-cards'>
                                    {speakers.map(speaker => {
                                        return (
                                            <SpeakersCard key={speaker.id} speaker={speaker} />
                                        )
                                    })}
                                </div>
                                <div className={`show-more-btn-speakers ${speakersCardHeight < 645 ? 'hide' : ''}`} onClick={() => setExpandSpeakers(!expandSpeakers)} >
                                    <p>{expandSpeakers ? 'Less Speakers' : 'More Speakers'}</p>
                                    <img alt='arrow down' className={expandSpeakers ? 'episodes-arrow-up' : 'episodes-arrow-down'} src={arrowDown} />
                                </div>
                            </div>
                        }
                    </div>
                    <div className='bottom-section-episodes-wrapper' style={expandEpisodes ? { maxHeight: episodesCardHeight + 'px' } : { maxHeight: '960px' }}>
                        <div className='bottom-section-episodes'>
                            <div className='bottom-section-inner-episodes'>
                                {episodes.length > 0 ?
                                    sortArray(episodes, { by: 'date', order: 'desc' }).map(episode => {
                                        return (
                                            <EpisodeCard
                                                episode={{ ...episode, event: { id: event.id, title: event.title } }}
                                                key={episode.id}
                                                hideViewMoreBtn
                                                whiteFont
                                            />
                                        )
                                    })
                                    :
                                    <div className='event-page-no-episodes'>
                                        <p>Currently there are no episodes from this event.</p>
                                        <p>Check this event later, or visit our calendar to view all past and upcoming events by clicking
                                            <span onClick={() => navigate('/calendar')}> here</span>.
                                        </p>
                                        {event.event_page !== '' &&
                                            <p>
                                                You can also <span onClick={() => openInNewTab(event.event_page)}>click here</span> to view all information on the event website.
                                            </p>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={`show-more-btn-episodes ${episodesCardHeight < 960 ? 'hide' : ''}`} onClick={() => setExpandEpisodes(!expandEpisodes)} >
                            <p>{expandEpisodes ? 'Less episodes' : 'More episodes'}</p>
                            <img alt='arrow down' className={expandEpisodes ? 'episodes-arrow-up' : 'episodes-arrow-down'} src={arrowDown} />
                        </div>
                    </div>
                </div>
                <MaybeYouLikeSection title='You may also like' />
                <LoginSignupMainDialog redirectOnClose={true} open={loginDialog} preventClose={true} close={() => setLoginDialog(false)} tabIndex={0} />
                {userHasAccess && <PrivateInfoDialog open={openPrivate} close={() => setOpenPrivate(false)} entity='event' id={event.id} />}
                <UserAuth open={openUserAuth} close={() => setOpenUserAuth(false)} />
            </div>
        ) : (
            <div>
                <Backdrop sx={{ color: '#fff' }} open={true}>
                    <ThreeDots fill='var(--orange)' />
                </Backdrop>
            </div>
        )
    )
}

export default Event

const EventChip = ({ name, array }) => {
    if (array.length === 0) return ''
    return (
        <div className='event-list-chip' >
            <p>{name}:
                {array.map((el, index) => (
                    <span key={el.id} className='chip-span' >
                        {index === 0 ? ` ${el.name}` : `, ${el.name}`}
                    </span>
                ))}
            </p>
        </div>
    )
}