import React, { useState, useEffect, useRef } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { RightArrowFeatured, LeftArrowFeatured } from '../horizontalScroll/ArrowsFeatureEp'
import useDrag from '../horizontalScroll/useDrag'
import { ThreeDots } from 'react-loading-icons'
import EpisodeCard from '../Events/EpisodeCard'
import { getFeaturedEpisodes } from '../../API/requests'

import './FeaturedEpisodesContainer.css'

function FeaturedEpisodesContainer() {
    const [featuredEpisodes, setFeaturedEpisodes] = useState([])
    const [episodeCardWindowFull, setEpisodeCardWindowViewFull] = useState(false);
    const { dragStart, dragStop, handleDrag } = useDrag();
    const [loading, setLoading] = useState(true)

    const scrollContainerRef = useRef();
    const cardContainerRef = useRef();

    useEffect(() => {
        setLoading(true)
        getFeaturedEpisodes().then(res => {
            setFeaturedEpisodes(res.data)
            setLoading(false)
        }, err => {
            console.log(err)
            setLoading(false)
        })
    }, [])


    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            window.requestAnimationFrame(() => { //Prevents Webpack error
                setEpisodeCardWindowViewFull(340 * featuredEpisodes.length > entries[0].contentRect.width);
            })
        })

        const container = cardContainerRef.current
        if (container) observer.observe(container);
        return () => { if (container) observer.unobserve(container) }
    }, [cardContainerRef, featuredEpisodes])


    return (
        featuredEpisodes.length > 0 &&
        <div className='featured-episodes-main-div' ref={cardContainerRef}>
            <div className='featured-episodes-title'>
                <p>Featured Episodes</p>
            </div>
            {loading ?
                <div style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                    <ThreeDots fill='var(--orange)' />
                </div>
                :
                <div
                    className='featured-episodes-cards'
                    onMouseLeave={dragStop}
                    style={!episodeCardWindowFull ? { display: 'flex', justifyContent: 'center' } : {}}
                >
                    {featuredEpisodes.length === 0 ? (
                        <p style={{ color: 'white', fontSize: '26px' }}>No episodes to display</p>
                    ) : (
                        <ScrollMenu
                            apiRef={scrollContainerRef}
                            LeftArrow={LeftArrowFeatured}
                            RightArrow={RightArrowFeatured}
                            onMouseDown={() => dragStart}
                            onMouseUp={() => dragStop}
                            onMouseMove={handleDrag}
                            itemClassName='scroll-container-episode-item'
                        >
                            {featuredEpisodes.map(episode => {
                                return (
                                    <EpisodeCard
                                        key={episode.id}
                                        episode={episode}
                                        whiteFont={false}
                                    />
                                )
                            })}
                        </ScrollMenu>
                    )}
                </div>
            }
        </div>
    )
}

export default FeaturedEpisodesContainer