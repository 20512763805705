import React, { useState, useEffect, useRef } from 'react'
import { RightArrowTopics, LeftArrowTopics } from '../horizontalScroll/ArrowsTopicsCards'
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import TopicCard from '../Events/TopicCard';
import useDrag from '../horizontalScroll/useDrag';
import sortArray from 'sort-array'
import { getEntity } from '../../API/requests';

import './TopicsContainer.css'

function TopicsContainer() {
    const [allTopics, setAllTopics] = useState([])
    const [topicsCardsWindowViewFull, setTopicsCardsWindowViewFull] = useState(false);

    const scrollContainerRef = useRef();
    const cardContainerRef = useRef()
    const { dragStart, dragStop, handleDrag } = useDrag();

    useEffect(() => {
        getEntity('topics', null, { withEpisodeCount: true }).then(res => {
            setAllTopics(res.data)
        }, err => {
            console.log(err)
        })
    }, [])


    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            window.requestAnimationFrame(() => { //Prevents Webpack error
                if (allTopics) {
                    setTopicsCardsWindowViewFull(340 * allTopics.length > entries[0].contentRect.width);
                }
            })
        })

        const container = cardContainerRef.current
        if (container) observer.observe(container);
        return () => { if (container) observer.unobserve(container) }
    }, [cardContainerRef, allTopics])


    return (
        <div className='home-topics-cards-container' ref={cardContainerRef}>
            <div className='home-topics-cards-title'>
                <p>Events by Topic</p>
            </div>
            <div
                className='home-topics-cards-div'
                onMouseLeave={dragStop}
                style={!topicsCardsWindowViewFull ? { display: 'flex', justifyContent: 'center' } : {}}
            >
                {allTopics.length === 0 ? (
                    <p style={{ color: 'white', fontSize: '26px' }}>no topics to display</p>
                ) : (
                    <ScrollMenu
                        apiRef={scrollContainerRef}
                        LeftArrow={LeftArrowTopics}
                        RightArrow={RightArrowTopics}
                        onMouseDown={() => dragStart}
                        onMouseUp={() => dragStop}
                        onMouseMove={handleDrag}
                        itemClassName='scroll-container-topics-item'
                    >
                        {sortArray(allTopics, { by: 'name' }).map(topic => {
                            return (
                                <TopicCard key={topic.id} topic={topic} />
                            )
                        })}
                    </ScrollMenu>
                )}
            </div>
        </div>
    )
}

export default TopicsContainer