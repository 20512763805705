import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material';
import { ThreeDots } from 'react-loading-icons'
import { UserContext } from '../contexts/User'
import { loginUser } from '../UserInfo/UserLog'
import Confirmation from '../Dialogs/Confirmation';

import './LoginDialog.css'
import { loginUserMarketo } from '../../API/requests';

let rememberMe = false; //Marketo cb is changing the state when called making remeberMe with useState return to false before the request to login

function LoginDialog(props) {
    const [rememberLogin, setRememberLogin] = useState(false)
    const [loadingScriptError, setLoadingScriptError] = useState(false)
    const [showLoad, setShowLoad] = useState(true)
    const [userState, dispatch] = React.useContext(UserContext)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationDialogData, setConfirmationDialogData] = useState({ error: false, title: '', text: '', buttonLabel: 'OK' })
    const [waitText, setWaitText] = useState('Please Wait...')

    const formCallback = (form) => {
        form.onSuccess((values, followUpUrl) => {
            loginUserDispatch(values.Email)
            return false;
        })

        window.MktoForms2.whenReady((form) => {
            setShowLoad(false)
        })
    }

    const CustomCheckBox = (props) => {
        return (
            <Checkbox
                {...props}
                checked={rememberLogin}
                onChange={(e) => { setRememberLogin(e.target.checked); rememberMe = e.target.checked }}
                sx={{
                    color: 'var(--pink)',
                    '&.Mui-checked': {
                        color: 'var(--pink)'
                    }
                }}
            />
        )
    }

    const loginUserDispatch = async (email) => {
        setShowLoad(true)
        setWaitText('Logging you in...')

        loginUserMarketo(email).then(res => {
            loginUser(`${res.data.name}`, res.data.token, res.data.email, rememberMe);
            dispatch({ type: 'login_user' });
            if (res.data.access) {
                dispatch({ type: 'access_user' });
            }
            // setConfirmationDialogData({ error: false, title: `Welcome back, ${res.data.name}`, text: 'You can now watch all episodes ' })
            // setShowConfirmation(true)
            rememberMe = false;
            setShowLoad(false)
            props.close()
        }, err => {
            rememberMe = false;
            setConfirmationDialogData({ error: true, title: `Opps!`, text: `Seems like you've not engaged with Leader Insights events yet! Sign up to get access! `, buttonLabel: 'Sign up' })
            setShowConfirmation(true)
            setShowLoad(false)
        })
    }

    const handleConfirmationDialogclose = () => {
        if (confirmationDialogData.error) {
            setShowConfirmation(false);
            props.changetab();
        }
        else props.close()
    }

    useEffect(() => {
        if (!window.MktoForms2) {
            return setLoadingScriptError(true);
        }

        window.MktoForms2.loadForm('//app-sjp.marketo.com', '195-OTS-308', '1455', formCallback);

    }, [])

    return (
        loadingScriptError ? (
            <p className='marketo-form-loading-error-message'>
                {`Failed to load form

                 Please check your browser extentions, some might be blocking the form to load, look for 'app-sjp.marketo.com' on your blocked links`}
            </p>
        ) : (
            <div className="login-form-main-div">
                <form id={`mktoForm_1455`} className='login-marketo-form' />
                {showLoad ? (
                    <div className='threedots-div'>
                        <ThreeDots fill='var(--pink)' />
                        <p style={{ marginTop: '15px', textAlign: 'center' }}>{waitText}</p>
                    </div>
                ) : (
                    <div className='login-text-checkbox'>
                        <p className='login-text'>*Use the email you've previously registered with to access the content.</p>
                        <FormControlLabel control={<CustomCheckBox />} label='Keep me logged in' />
                        {/* <button onClick={() => loginUserDispatch('joaorosa@faunalab.com')}>Fake login</button> */}
                    </div>
                )}
                <Confirmation {...confirmationDialogData} open={showConfirmation} close={handleConfirmationDialogclose} />
            </div>
        )
    );
}

export default LoginDialog
