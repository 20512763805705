import React from 'react'
import ContactForm from '../components/Forms/ContactForm'
import FaqAccordion from '../components/Accordion/FaqAccordion'

import './css/help.css'

function Help() {
    return (
        <div className='help-main-div'>
            {/* <div className='FAQ-div'>
                <p>FAQs</p>
                <div className='faq-accordions'>
                    <FaqAccordion />
                </div>
            </div> */}
            <div className='contact-div'>
                <div className='contacts-title'>
                    <p>Questions? Contact us.</p>
                    <p>Send us your contact details and your request or question and we will get back to you.</p>
                </div>
                <ContactForm />
            </div>
        </div>
    )
}

export default Help
