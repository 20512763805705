import React from 'react'
import './Footer.css'
import { openInNewTab } from '../../utils'

import Logo from '../../assets/navbar/TP_logo.svg'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';


const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'var(--purple)',
        color: 'white',
        maxWidth: 'none',
        border: 'none',
    },
}));

function Footer() {
    return (
        <div className='footer-container'>
            <div className='footer-content-top'>
                <img alt='logo' src={Logo} onClick={() => openInNewTab('https://www.teleperformance.com/en-us/')} />

            </div>
            <div className='footer-content'>
                <div className='footer-links'>
                    <ExternalLink url='https://www.teleperformance.com/en-us/footer/data-privacy-policy'>Data Privacy Policy</ExternalLink>
                    <ExternalLink url='https://www.teleperformance.com/en-us/footer/legal-statement'>Codes and Policies</ExternalLink>
                    <ExternalLink url='https://www.teleperformance.com/en-us/footer/legal-statement'>Legal Statement</ExternalLink>
                    <ExternalLink url='https://www.teleperformance.com/en-us/footer/ethics-hotline'>Ethics Hotline</ExternalLink>
                </div>
                <p>{`Copyright © ${new Date().getFullYear()}. All rights reserved`}.</p>
            </div>
        </div>
    )
}

const ExternalLink = ({ url, children }) => {
    return (
        <StyledTooltip title={url}>
            <p className='footer-link' onClick={() => openInNewTab(url)}>{children}</p>
        </StyledTooltip>
    )
}

export default Footer
