import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { trackViewReq } from "./API/requests";

export function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

export function debounce(fn, ms) {
	let timer
	return _ => {
		clearTimeout(timer)
		timer = setTimeout(_ => {
			timer = null
			fn.apply(this, arguments)
		}, ms)
	};
}

export const stringToColor = (string, colorSpace) => {
	if (string === undefined) string = 'No String'

	let hash = 0;
	let i;

	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.substr(-2);
	}

	return color
}

export const generateTumbnailImageFromVideoUrl = (videoUrl) => {
	if (!videoUrl || videoUrl.length === 0) return `${process.env.REACT_APP_SERVER_URL}/resources/images/episodes/placeholder.png`
	const url = new URL(videoUrl)
	const videoID = videoUrl.split('/')[3];
	const imageID = url.searchParams.get('photo_id');
	const token = url.searchParams.get('token');
	const size = '800x' // or 400x200cr
	return `https://teleperformance.videomarketingplatform.co/${videoID.split('.')[0]}/${imageID}/${token}/${size}/thumbnail.jpg`
}

export const trackView = async (entity, entity_id, user) => {
	trackViewReq({ entity, entity_id, user })
}

const colorArr = ['#33204C', '#A2225E', '#E5592F', '#2F3983', '#51A897', '#34659F', '#5A3283', '#FF0087', '#F0956B', '#6D75A8',
	'#94C0B6', '#4C8FE3', '#8672A4', '#E95CA8', '#F7C2AB', '#C0B4CD', '#EF9FCD', '#ABAFCC', '#C1DBD5', '#9FC6F1']

export const getColorForEvent = (id) => {
	const remainder = id % 20; // get the color even if the event id is greater than the array length
	return colorArr[id - 1] ? colorArr[id - 1] : colorArr[remainder];
}

export const openInNewTab = (url) => {
	const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
	if (newWindow) newWindow.opener = null
}

export function useDebounce(value, delay) { //debounce to controlled components
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(
		() => {
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay]
	);
	return debouncedValue;
}

export function mapDistinctArrayOfObjects(array, property) {

	const newArray = [].concat(...array.map(el => el[property]))

	const distinctMap = new Map();

	newArray.forEach(el => {
		distinctMap.set(el.id, el)
	})

	return [...distinctMap.values()]
}