import React, { useState, useEffect } from 'react'
import { UserContext } from '../components/contexts/User'
import { useNavigate } from 'react-router-dom'
import LoginSignupMainDialog from '../components/Login-signup/LoginSignupMainDialog'
import { Backdrop } from '@mui/material'
import { ThreeDots } from 'react-loading-icons'
import MaybeYouLikeSection from '../components/MaybeYouLike/MaybeYouLikeSection'
import Breadcrumb from '../components/Breadcrumbs/Breadcrumb'
import { getEntity } from '../API/requests'
import EventCard from '../components/Events/EventCard'

import './css/serie.css'


function Serie() {
    const [serie, setSerie] = useState()
    const [userState, dispatch] = React.useContext(UserContext)
    const [loginDialog, setLoginDialog] = useState(false);
    const navigate = useNavigate()
    const urlParams = new URLSearchParams(window.location.search)

    useEffect(() => {

        if (urlParams.get('id')) {
            getEntity('series', urlParams.get('id')).then(res => {
                setSerie(res.data)
            }, err => {
                console.log(err)
                navigate('/')
            })
        } else {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (serie) {
            if (!userState.logged && serie.private) {
                setLoginDialog(true)
            } else {
                setLoginDialog(false)
            }
        }
    }, [userState, serie])


    return (
        serie ? (
            <div className='eventpage-main-div'>
                <Breadcrumb serie={serie} entity={'serie'}/>
                <div className='event-page-top-image'>
                    <img alt='promoted-event' className='top-image' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/series/${serie.landscape_image}`} />
                    <div className='top-video-black-div'></div>
                    <div className='top-video-static-black-div'></div>
                    <div className='top-video-info'>
                        <p className='top-video-title'>{serie.title}</p>
                    </div>
                </div>
                <div className='series-page-bottom-section'>
                    <div className='bottom-section-text'>
                        <p className='serie-text-description'>{serie.description}</p>
                    </div>
                    <p className='series-event-container-title'>Events</p>
                    <div className='serie-events-container'>
                        {serie.events.map(event => (
                                <EventCard key={event.id} event={event} />
                        ))}
                    </div>
                </div>
                <MaybeYouLikeSection title='You may also like' />
                <LoginSignupMainDialog redirectOnClose={true} open={loginDialog} preventClose={true} close={() => setLoginDialog(false)} tabIndex={0} />
            </div>
        ) : (
            <div>
                <Backdrop sx={{ color: '#fff' }} open={true}>
                    <ThreeDots fill='var(--orange)' />
                </Backdrop>
            </div>
        )
    )
}

export default Serie
