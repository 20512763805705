import React from 'react';
import { Avatar, Tooltip } from '@mui/material';
import './SpeakersCard.css'

import { LinkedIn as WebIcon } from '@mui/icons-material';


const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null
}

function SpeakersCard({ speaker }) {
    return (
        <div className='speaker-card-main-div'>
            {speaker.portrait ? (
                <Avatar className='speaker-avatar' alt={speaker.name} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/speakers/${speaker.portrait}`} />
            ): (
                <Avatar className='speaker-avatar' alt={speaker.name} />
            )}
            <div>
                <p className='speaker-name-text'>{speaker.name}</p>
                <p className='speaker-job-text'>{speaker.job_title}</p>
                <Tooltip disableInteractive title={`${speaker.name} LinkedIn`} >
                    <WebIcon className='web-icon' onClick={() => openInNewTab(speaker.social_links)}/>
                </Tooltip>
            </div>
        </div>
    );
}

export default SpeakersCard;
