import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Divider } from '@mui/material'
import { getUserName, logoutUser } from '../UserInfo/UserLog'

import { Menu as MenuIcon, Close as CloseIcon, Logout } from '@mui/icons-material'
import { IconButton, ClickAwayListener, Menu, MenuItem, ListItemIcon } from '@mui/material'

import LoginSignupMainDialog from '../Login-signup/LoginSignupMainDialog'

import Logo from '../../assets/navbar/LEADER_LOGO.svg'
import Login from '../../assets/navbar/login.svg'
import TPlogo from '../../assets/navbar/TP_logo.svg'
import WorldSvg from '../../assets/navbar/world.svg'
import NewLogo from '../../assets/navbar/NewLogo.png'

import { openInNewTab } from '../../utils'
import './Navbar.css'
import { UserContext } from '../contexts/User'

const menuStyle = {
    elevation: 0,
    sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
}

function Navbar() {
    const [click, setClick] = useState(false)
    const [openLoginDialog, setOpenLoginDialog] = useState(false);
    const [dialogTabIndex, setDialogTabIndex] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null);
    const [userState, dispatch] = React.useContext(UserContext)
    const [userName, setUserName] = useState('User Name')

    const handleLoginSignupDialog = (tabIndex) => {
        setClick(false);
        setDialogTabIndex(tabIndex)
        setOpenLoginDialog(true)
    }

    const logoutUserDispatch = () => {
        dispatch({ type: 'logout_user' });
        dispatch({ type: 'unauth_user' });
        setAnchorEl(null)
        logoutUser();
    }

    useEffect(() => {
        setUserName(getUserName())
    }, [userState.logged])

    return (
        <div>
            <div className='top-navbar-div'>
                <div className='presented-by'>
                    <p>presented by</p>
                    <img alt='tp-logo' src={TPlogo} className='tp-logo' onClick={() => openInNewTab('https://www.teleperformance.com/')} />
                </div>
                <Divider orientation='vertical' className='navbar-divider' />
                <div className='global-website' onClick={() => openInNewTab('https://www.teleperformance.com/')}>
                    <img alt='world' src={WorldSvg} className='world-icon' />
                    <p>Global Website</p>
                </div>
            </div>
            <nav className='navbar-main'>
                <div className='navbar-logo'>
                    <NavLink to='/' onClick={() => setClick(false)} style={{height:'50%'}}>
                        <img alt='logo' src={NewLogo} className='logo' style={{height: '100%'}}/>
                    </NavLink>
                </div>
                <ClickAwayListener onClickAway={() => setClick(false)}>
                    <div className='navbar-container'>
                        <div className='menu-icon'>
                            <IconButton onClick={() => setClick(!click)}>
                                {click ? <CloseIcon /> : <MenuIcon />}
                            </IconButton>
                        </div>
                        <div className={click ? 'navbar-tabs active' : 'navbar-tabs'}>
                            <div className='navbar-main-links'>
                                <NavLink to='/episodes' className={({ isActive }) => isActive ? 'navbar-tab-active' : 'navbar-tab'} onClick={() => setClick(false)}>
                                    <div className='bottom-border'></div>
                                    <p>SEARCH</p>
                                </NavLink>
                                <NavLink to='/calendar' className={({ isActive }) => isActive ? 'navbar-tab-active' : 'navbar-tab'} onClick={() => setClick(false)}>
                                    <div className='bottom-border'></div>
                                    <p>CALENDAR</p>
                                </NavLink>
                                <NavLink to='/about' className={({ isActive }) => isActive ? 'navbar-tab-active' : 'navbar-tab'} onClick={() => setClick(false)}>
                                    <div className='bottom-border'></div>
                                    <p>ABOUT</p>
                                </NavLink>
                                {/* <NavLink to='/help' className={({ isActive }) => isActive ? 'navbar-tab-active' : 'navbar-tab'} onClick={() => setClick(false)}>
                                    <div className='bottom-border'></div>
                                    <p>FAQs</p>
                                </NavLink> */}
                            </div>
                            {/* <Divider orientation='vertical' className='navbar-divider' /> */}
                            <Divider orientation='horizontal' className='navbar-divider-mobile' />
                            <div className='navbar-login-div'>
                                <img className='login-svg' alt='navbar-login' src={Login} />
                                <div>
                                    {userState.logged ? (
                                        <>
                                            <p className='loggedin-user' onClick={(e) => setAnchorEl(e.currentTarget)}>{userName}</p>
                                            <Menu
                                                id='profile-menu'
                                                PaperProps={menuStyle}
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={() => setAnchorEl(null)}
                                            >
                                                <MenuItem onClick={() => logoutUserDispatch()}>
                                                    <ListItemIcon>
                                                        <Logout fontSize="small" />
                                                    </ListItemIcon>
                                                    Logout
                                                </MenuItem>
                                            </Menu>
                                        </>
                                    ) : (
                                        <>
                                            <p className='login-text' onClick={() => handleLoginSignupDialog(0)}>LOG IN</p>
                                            <p style={{ whiteSpace: 'pre-wrap' }}>{` / `}</p>
                                            <p className='login-text' onClick={() => handleLoginSignupDialog(2)}>SIGN UP</p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </ClickAwayListener>
            </nav>
            <LoginSignupMainDialog open={openLoginDialog} close={() => setOpenLoginDialog(false)} tabIndex={dialogTabIndex} />
        </div>
    )
}

export default Navbar
