import { useState, useCallback, useRef } from "react";

export default function useDrag() {
    const [clicked, setClicked] = useState(false);
    const [dragging, setDragging] = useState(false);
    const position = useRef(0);

    const dragStart = useCallback(ev => {
        position.current = ev.clientX;
        setClicked(true);
    }, []);

    const dragStop = useCallback(() => window.requestAnimationFrame(() => {
        setDragging(false);
        setClicked(false);
    }),
        []
    );

    const dragMove = (ev, cb) => {
        var newDiff = position.current - ev.clientX;
        var movedEnough = Math.abs(newDiff) > 5;
        if (clicked && movedEnough) {
            setDragging(true);
        }
        if (dragging && movedEnough) {
            position.current = ev.clientX;
            cb(newDiff);
        }
    };

    const handleDrag = function (apiObj) {
        const scrollContainer = apiObj.scrollContainer.current;
        return function (ev) {
            return dragMove(ev, posDiff => {
                if (scrollContainer) {
                    scrollContainer.scrollLeft += posDiff;
                }
            });
        };
    };

    return {
        dragStart,
        dragStop,
        dragging,
        position,
        setDragging,
        handleDrag
    };
}