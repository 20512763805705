import { useContext, useEffect, useRef } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { IconButton } from "@mui/material";

import useWheel from "./useWheel";

import './Arrows.css'

export const RightArrow = () => {
    const { sideScroll } = useWheel();
    const { scrollContainer } = useContext(VisibilityContext);
    const arrowRef = useRef(null)
    const lastCard = document.querySelector(".scroll-container-event-item:last-child")

    const observer = new IntersectionObserver(entries => {
        if (arrowRef.current) {
            arrowRef.current.classList.toggle("show", !entries[0].isIntersecting)
        }
    }, { threshold: 1, rootMargin: '300px 0px' })

    useEffect(() => {
        if (lastCard && arrowRef) observer.observe(lastCard);
        return () => { if(lastCard) observer.unobserve(lastCard) }
    }, [arrowRef, lastCard])

    return (
        <IconButton
            ref={arrowRef}
            className='arrow-btn-right'
            style={{ top: '45%'}}
            onClick={() => sideScroll(scrollContainer.current, 'right', 10, 620, 10)}
        >
            <ArrowRight sx={{ transform: 'scale(2)' }} />
        </IconButton>
    )
}

export const LeftArrow = () => {
    const { sideScroll } = useWheel();
    const { scrollContainer } = useContext(VisibilityContext);
    const arrowRef = useRef(null)
    const firstCard = document.querySelector(".scroll-container-event-item:first-child")

    const observer = new IntersectionObserver(entries => {
        if (arrowRef.current) {
            arrowRef.current.classList.toggle("show", !entries[0].isIntersecting)
        }
    }, { threshold: 1, rootMargin: '300px 0px' })

    useEffect(() => {
        if (firstCard && arrowRef) observer.observe(firstCard);
        return () => { if(firstCard) observer.unobserve(firstCard) }
    }, [arrowRef, firstCard])

    return (
        <IconButton
            ref={arrowRef}
            className='arrow-btn-left'
            style={{ top: '45%'}}
            onClick={() => sideScroll(scrollContainer.current, 'left', 10, 620, 10)}
        >
            <ArrowLeft sx={{ transform: 'scale(2)' }} />
        </IconButton>
    )
}
