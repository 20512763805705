import React from 'react'

import './css/notFound.css'

function NotFound() {
    return (
        <div className='not-found-main-div'>
           <p className='not-found-title'> Page not found</p> 
        </div>
    )
}

export default NotFound
