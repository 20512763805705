import React, { useImperativeHandle, useState, useEffect } from 'react'
import { Checkbox, TextField, Autocomplete, Popper } from '@mui/material'
import { CheckBoxOutlineBlank, CheckBox, KeyboardArrowDown } from '@mui/icons-material'
import { styled } from '@mui/material/styles'


const CustomTextfield = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        border: '1px solid rgb(34,26,42)',
        borderRadius: '2rem',
        width: '150px',
        height: '36px',
        padding: '0',

        '&:hover': {
            border: '1px solid #E8954E',
            '& fieldset': {
                borderColor: 'transparent'
            },
            '& .MuiOutlinedInput-input::placeholder': {
                color: '#E8954E'
            },
            ' & svg': {
                color: '#E8954E'
            }
        },
        '&.Mui-focused': {
            border: '2px solid #B14282',
            outline: 'none',
            '& fieldset': {
                borderColor: 'transparent'
            },
            '& .MuiOutlinedInput-input::placeholder': {
                color: 'rgba(177,66,130,0.5)'
            },
            ' & svg': {
                color: '#B14282'
            }
        },
        '& .MuiAutocomplete-input': {
            fontWeight: '400',
            padding: '0 15px',
        }
    },
});

const CustomPopper = (props) => {
    return <Popper {...props} style={{ width: 'max-content', maxWidth: '300px', minWidth: '150px' }} />
}

const removeElementFromArray = (value, array) => {
    return array.filter(elem => elem.id !== value.id)
}

export const DropDownFilter = React.forwardRef((props, ref) => {
    const [values, setValue] = useState([]);
    const [data, setData] = useState([])

    useImperativeHandle(ref, () => ({
        deleteOnChild(value) {
            setValue(removeElementFromArray(value, values));
        },
        removeAll() {
            setValue([])
        }
    }))

    useEffect(() => {
        if (props.isDataObject) {
            const data = [...props.data].map(elem => {
                return { id: elem.id, value: elem.title ? elem.title : elem.name }
            })
            setData(data)
        } else {
            const data = [...props.data].map(elem => {
                return { id: elem, value: elem }
            })
            setData(data)
        }
    }, [props])

    useEffect(() => {
        props.filter(values, props.label)
    }, [values])

    return (
        <Autocomplete
            multiple
            id="filter-dropdown"
            options={data}
            disableCloseOnSelect
            limitTags={1}
            PopperComponent={CustomPopper}
            openOnFocus
            renderTags={() => null}
            value={values}
            onChange={(e, newValue) => setValue(newValue)}
            popupIcon={<KeyboardArrowDown fontSize='small' />}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, { selected }) => (
                <li {...props} style={{ height: 'fit-content', fontSize: '14px' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize='small' />}
                        checkedIcon={<CheckBox fontSize='small' />}
                        style={{ color: '#B14282' }}
                        checked={selected}
                    />
                    {option.value}
                </li>
            )}
            renderInput={(params) => (
                <CustomTextfield {...params} placeholder={props.placeholder} />
            )}
        />
    )
})

