import React from 'react'
import { TextField } from '@mui/material'
import { Search, Clear } from '@mui/icons-material'
import { styled } from '@mui/material/styles'

const CustomTextfield = styled(TextField)({
    width: '90%',
    maxWidth: '400px',
    alignItems: 'center',
    '& .MuiOutlinedInput-root': {
        paddingLeft: '10px',
        border: '1px solid rgb(34,26,42)',
        borderRadius: '2rem',
        width: '90%',
        height: '36px',
        transition: 'width 0.3s ease-in-out',
        '&:hover': {
            width: '100%',
            border: '1px solid #E8954E',
            '& fieldset': {
                borderColor: 'transparent'
            },
            '& .MuiOutlinedInput-input::placeholder': {
                color: '#E8954E'
            },
            ' & svg': {
                color: '#E8954E'
            }
        },
        '&.Mui-focused': {
            border: '2px solid #B14282',
            outline: 'none',
            width: '100%',
            '& fieldset': {
                borderColor: 'transparent'
            },
            '& .MuiOutlinedInput-input::placeholder': {
                color: 'rgba(177,66,130,0.5)'
            },
            ' & svg': {
                color: '#B14282'
            }
        }
    },
});

function SearchInput({ clear, ...props }) {
    return (
        <CustomTextfield
            placeholder='Search event name, location or serie'
            InputProps={{
                startAdornment: <Search sx={{ marginRight: '10px' }} />,
                endAdornment: props.value.length > 0 && <Clear sx={{ cursor: 'pointer' }} onClick={clear} />
            }}
            type='none'
            {...props}
        />
    )
}

export default SearchInput