export const reducer = (state, action) => {
    switch (action.type) {
        case 'login_user':
            return {
                ...state,
                logged: true
            }
        case 'logout_user':
            return {
                ...state,
                logged: false
            }
        case 'auth_user':
            return {
                ...state,
                auth: true
            }
        case 'unauth_user':
            return {
                ...state,
                auth: false
            }
        case 'access_user':
            return {
                ...state,
                access: true
            }
        default:
            return state
    }
}

export const initialState = {
    logged: false,
    auth: false,
    access: false
}