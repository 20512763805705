import React, { useState, useEffect } from 'react'
import regexifyString from 'regexify-string'
import { getEntity } from '../API/requests'

import './css/about.css'

const getBackgroundStyle = (section) => {

    const style = {};

    if (section.gradient) {
        style.backgroundImage = `linear-gradient(${section.gradient_angle}deg,${section.gradient_color_1}, ${section.gradient_color_2})`
    } else {
        style.backgroundColor = section.background_color
    }

    if (section.section_image) {
        if (section.image_position === 'left') {
            style.flexDirection = 'row-reverse'
        }
    } else {
        style.textAlign = 'center'
    }

    return style
}

const getMainDivStyle = (section) => {
    if (!section) return;

    if (Object.keys(section).length === 0) {
        return { background: 'linear-gradient: (to right, var(--pink), var(--orange)' }
    }
    if (Object.keys(section).length === 1) {
        if (section[0].gradient) {
            return { backgroundImage: `linear-gradient(${section[0].gradient_angle}deg,${section[0].gradient_color_1}, ${section[0].gradient_color_2})` }
        } else {
            return { backgroundColor: section[0].backgroundColor }
        }
    }

    return { background: 'white' }
}

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null
}

const regexifyPaterm = /\[\[(.*?)\]\]/gim;
const regexifyDecorator = (match, index) => {
    const string = match.substring(2, match.length - 2)
    return <p key={match} className='text-link' onClick={() => openInNewTab(string.split('|')[1])}>{string.split('|')[0]}</p>;
}

function About() {
    const [data, setData] = useState([])

    useEffect(() => {
        getEntity('aboutus').then(res => {
            setData(res.data.filter(el => el.online))
        }, err => {
            console.log(err)
        })
    },[])

    return (
        <div className='about-main-div' style={getMainDivStyle(data)}>
            {data && data.map(section => {
                return (
                    <div key={section.id} className='section-div' style={getBackgroundStyle(section)} >
                        <div className={`section-text${section.section_image ? `-image${section.image_position === 'right' ? '-right' : '-left'}` : ''}`} style={{ color: section.text_color }}>
                            <div className='section-text-title'>{section.title}</div>
                            <div className='section-text-content'>{regexifyString({ pattern: regexifyPaterm, decorator: regexifyDecorator, input: section.text })}</div>
                        </div>
                        {section.section_image &&
                            <div className='section-img'>
                                <img className='about-image' alt='about-section-1' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/aboutus/${section.section_image}`} />
                            </div>
                        }
                    </div>
                )
            })}
        </div>
    )
}


export default About