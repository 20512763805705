import React, { useState, useEffect } from 'react'
import MaybeYouLikeSection from '../components/MaybeYouLike/MaybeYouLikeSection'
import { getCalendarData } from '../API/requests'

import './css/calendar.css'
import CalendarList from '../components/Calendar/CalendarList'

function CalendarPage() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getCalendarData().then(res => {
            setData(res.data)
            setLoading(false)
        }, err => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    return (
        <div className='calendar-main-div'>
            {data ?
                <>
                    <CalendarList events={data} loading={loading} />
                    <MaybeYouLikeSection title='You may also like' />
                </>
                : ''}
        </div>
    )
}

export default CalendarPage
