import React, { useState, useEffect, useRef } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { RightArrowMYL, LeftArrowMYL } from '../horizontalScroll/ArrowsMYL'
import EpisodeCard from '../Events/EpisodeCard'
import useDrag from '../horizontalScroll/useDrag'
import sortArray from 'sort-array'
import { getUserSuggestions } from '../UserInfo/UserSuggestions'
import { getSuggestedEpisodes } from '../../API/requests'

import './MaybeYouLikeSection.css'

function MaybeYouLikeSection(props) {
    const scrollContainerRef = useRef();
    const cardContainerRef = useRef();
    const [episodeCardWindowFull, setEpisodeCardWindowViewFull] = useState(false);
    const [suggestedEpisodes, setSuggestedEpisodes] = useState([])
    const { dragStart, dragStop, handleDrag } = useDrag();

    const observer = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => { //Prevents Webpack error
            setEpisodeCardWindowViewFull(340 * suggestedEpisodes.length > entries[0].contentRect.width);
        })
    })

    useEffect(() => {
        const userSuggestions = getUserSuggestions();

        getSuggestedEpisodes({ userSuggestions }).then(res => {
            setSuggestedEpisodes(sortArray(res.data, { by: 'date' }))
        })

    }, [])

    useEffect(() => {
        const container = cardContainerRef.current
        if (container) observer.observe(container);
        return () => { if (container) observer.unobserve(container) }
    }, [cardContainerRef, suggestedEpisodes])

    return (
        <div className='myl-episodes-div' ref={cardContainerRef}>
            <div className='myl-episodes-title'>
                <p>{props.title}</p>
            </div>
            <div
                className='myl-episodes-cards'
                onMouseLeave={dragStop}
                style={!episodeCardWindowFull ? { display: 'flex', justifyContent: 'center' } : {}}
            >
                <ScrollMenu
                    apiRef={scrollContainerRef}
                    LeftArrow={LeftArrowMYL}
                    RightArrow={RightArrowMYL}
                    onMouseDown={() => dragStart}
                    onMouseUp={() => dragStop}
                    onMouseMove={handleDrag}
                    itemClassName='scroll-container-myl-episode-item'
                >
                    {suggestedEpisodes.map(episode => {
                        return (
                            <EpisodeCard
                                key={episode.id}
                                episode={episode}
                                whiteFont={true}
                            />
                        )
                    })}
                </ScrollMenu>
            </div>
        </div>
    )
}

export default MaybeYouLikeSection