import React, { useState, useEffect } from 'react';
import { Snackbar, Button } from '@mui/material';

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null
}

const policyUrl = 'https://www.teleperformance.com/media/4017496/data-privacy-policy_public-version_eng.pdf'
const message =
    <p style={{ userSelect: 'none'}}>
        {`We use cookies to help operate and improve your experience on our website and monitor its performance. By continuing to browse, you agree to our cookie policy.

    To learn more about how we use data, please see our `}
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => openInNewTab(policyUrl)}>Privacy Policy</span>
    </p>


function CookiesPopUp() {
    const [open, setOpen] = useState(true)

    useEffect(() => {
        if (localStorage.getItem('tnc') === 'true') {
            setOpen(false)
        }
    },[])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        localStorage.setItem('tnc', 'true')
    };

    return (
        <div>
            <Snackbar open={open}
                sx={{ maxWidth: '600px', whiteSpace: 'pre-line' }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleClose}
                message={message}
                action={<Button color='warning' size='small' onClick={handleClose}>Accept cookies</Button>}
            />
        </div>
    )
}

export default CookiesPopUp;
