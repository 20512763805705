import React, { useState, useEffect } from 'react'

import TopicsContainer from '../components/HomeSections/TopicsContainer'
import EventsContainer from '../components/HomeSections/EventsContainer'
import FeaturedEpisodesContainer from '../components/HomeSections/FeaturedEpisodesContainer'
import HighlightedContainer from '../components/HomeSections/HighlightedContainer'
import { getEntity } from '../API/requests'
import { Backdrop } from '@mui/material'
import { ThreeDots } from 'react-loading-icons'

import './css/home.css'

function Home() {
    const [allEvents, setAllEvents] = useState([])
    const [highlightedEvent, setHighlightedEvent] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        getEntity('events').then(res => {
            setAllEvents(res.data)
            setHighlightedEvent(res.data.find(event => event.highlighted))
            setLoading(false)
        }, err => {
            console.log(err)
            setLoading(false)
        })
    }, [])


    return (
        <div className='home-main-div'>
            {!loading ? (
                <>
                    <HighlightedContainer event={highlightedEvent} />
                    <EventsContainer data={allEvents} loading={loading} />
                    <TopicsContainer />
                    <FeaturedEpisodesContainer />
                </>
            ) : (
                <Backdrop sx={{ color: '#fff' }} open={true}>
                    <ThreeDots fill='var(--orange)' />
                </Backdrop>
            )}
        </div >
    )
}

export default Home
