import React, { useState } from 'react'
import './Buttons.css'
import playIcon from '../../assets/icons/playbutton.svg'
import plusSign from '../../assets/icons/plusSign.svg'

import LoginSignupMainDialog from '../Login-signup/LoginSignupMainDialog'

import { addSuggestionToUser } from '../UserInfo/UserSuggestions'
import useDrag from '../../components/horizontalScroll/useDrag'

import { UserContext } from '../contexts/User'
import { useNavigate } from 'react-router-dom'
import { Lock } from '@mui/icons-material'


const getButtonSize = (size) => {
    switch (size) {
        case 'small': return { button: { height: '34px', width: '112px', fontSize: '12px', paddingLeft: '5px' }, icon: { height: '23.5px', width: '23.5px' } };
        case 'medium': return { button: { height: '40px', width: '131px', fontSize: '13.5px', paddingLeft: '6px' }, icon: { height: '24px', width: '26px' } };
        case 'fitting-small': return { button: { height: '34px', width: 'fit-content', fontSize: '12px', paddingLeft: '5px', paddingRight: '10px' }, icon: { height: '23.5px', width: '23.5px' } };
        case 'fitting-medium': return { button: { height: '40px', width: 'fit-content', fontSize: '13.5px', paddingLeft: '6px', paddingRight: '10px' }, icon: { height: '24px', width: '26px' } };
        case 'medium-margin-right': return { button: { height: '40px', width: '131px', fontSize: '13.5px', paddingLeft: '6px', marginRight: '25px' }, icon: { height: '24px', width: '26px' } };
        case 'medium-wide': return { button: { height: '40px', width: '210px', fontSize: '13.5px', paddingLeft: '6px' }, icon: { height: '24px', width: '26px' } };
        default: return { button: { height: '40px', width: '131px', fontSize: '13.5px', paddingLeft: '6px' }, icon: { height: '24px', width: '26px' } };
    }
}

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
    if (newWindow) newWindow.opener = null
}

const mapElementsIds = (episode, element) => {
    return episode[element].map(el => el.id)
}

export const Combo = (props) => {
    const navigate = useNavigate();
    const { dragging } = useDrag();

    const [loginDialog, setLoginDialog] = useState(false)
    const [userState, dispatch] = React.useContext(UserContext)

    const handleEventNavigation = (event) => {
        if (dragging) {
            return false;
        }
        addSuggestionToUser({ speakers: [], events: [event.id], topics: [], industry: [] })
        navigate(`/event/${event.title.replace(/[;:,!*`?&/\\"<>|'\s]/g, '')}?id=${event.id}`, { state: { event: event } })
    }

    const handleEpisodeNavigation = (episode) => {
        if (dragging) {
            return false;
        }
        addSuggestionToUser({ speakers: mapElementsIds(episode, 'speakers'), events: [props.event.id], topics: mapElementsIds(episode, 'topics'), industry: mapElementsIds(episode, 'industries') })
        navigate(`/episodes/${episode.title.replace(/[;:,!*`?&/\\"<>|'\s]/g, '')}?id=${episode.id}`, { state: { episode: episode, event: props.event } })
    }

    return (
        <>
            {(props.event.private || props.episode.private) && !userState.logged && props.episode.available ? (
                <>
                    <div
                        style={getButtonSize(props.size).button}
                        className='watchnow-btn'
                        onClick={() => setLoginDialog(true)}
                        {...props}
                    >
                        <img style={getButtonSize(props.size).icon} alt='login' src={plusSign} />
                        {props.loginbtnlabel ? props.loginbtnlabel : 'Log in to view episode'}
                    </div>
                </>
            ) : (
                <div className='combo-buttons-div'>
                    {props.episode.available &&
                        <div
                            style={getButtonSize(props.size).button}
                            className='watchnow-btn'
                            onClick={() => handleEpisodeNavigation(props.episode)}
                        >
                            <img style={getButtonSize(props.size).icon} alt='play' src={playIcon} />
                            {props.watchnowbtnlabel ? props.watchnowbtnlabel : 'Watch now'}
                        </div>
                    }
                    <div
                        style={getButtonSize(props.size).button}
                        className='viewevent-btn'
                        onClick={() => handleEventNavigation(props.event)}
                    >
                        <img style={getButtonSize(props.size).icon} alt='event page' src={plusSign} />
                        {props.vieweventbtnlabel ? props.vieweventbtnlabel : 'View event'}
                    </div>
                </div>
            )}
            <LoginSignupMainDialog open={loginDialog} close={() => setLoginDialog(false)} tabIndex={0} />
        </>
    )
}

export const WatchNow = (props) => {
    const navigate = useNavigate();
    const [loginDialog, setLoginDialog] = useState(false)
    const [userState, dispatch] = React.useContext(UserContext)

    const handleEpisodeNavigation = (episode) => {
        addSuggestionToUser({ speakers: mapElementsIds(episode, 'speakers'), events: [props.event.id], topics: mapElementsIds(episode, 'topics'), industry: mapElementsIds(episode, 'industries') })
        navigate(`/episodes/${episode.title.replace(/[;:,!*`?&/\\"<>|'\s]/g, '')}?id=${episode.id}`, { state: { episode: episode, event: props.event } })
    }

    return (
        <>
            {(props.event.private || props.episode.private) && !userState.logged && props.episode.available ? (
                <>
                    <div
                        style={getButtonSize(props.size).button}
                        className='watchnow-btn'
                        onClick={() => setLoginDialog(true)}
                        {...props}
                    >
                        <img style={getButtonSize(props.size).icon} alt='play' src={plusSign} />
                        Log in to view episode
                    </div>
                </>
            ) : (
                props.episode.available &&
                <div
                    style={getButtonSize(props.size).button}
                    className='watchnow-btn'
                    onClick={() => handleEpisodeNavigation(props.episode)}
                    {...props}
                >
                    <img style={getButtonSize(props.size).icon} alt='play' src={playIcon} />
                    {props.watchnowbtnlabel ? props.watchnowbtnlabel : 'Watch now'}
                </div>
            )}
            <LoginSignupMainDialog open={loginDialog} close={() => setLoginDialog(false)} tabIndex={0} />
        </>
    )
}

export const ViewEvent = (props) => {
    const navigate = useNavigate();
    const { dragging } = useDrag();
    const [userState, dispatch] = React.useContext(UserContext)
    const [loginDialog, setLoginDialog] = useState(false)

    const handleEventNavigation = (event) => {
        if (dragging) {
            return false;
        }
        addSuggestionToUser({ speakers: [], events: [event.id], topics: [], industry: [] })
        navigate(`/event/${event.title.replace(/[;:,!*`?&/\\"<>|'\s]/g, '')}?id=${event.id}`, { state: { event: event } })
    }

    return (
        props.event.private && !userState.logged ? (
            <div
                style={getButtonSize(props.size).button}
                className='viewevent-btn'
                onClick={() => setLoginDialog(true)}
                {...props}
            >
                <img style={getButtonSize(props.size).icon} alt='play' src={plusSign} />
                {props.loginbtnlabel ? props.loginbtnlabel : 'Log in'}
                <LoginSignupMainDialog open={loginDialog} close={() => setLoginDialog(false)} tabIndex={0} />
            </div>
        ) : (
            <div
                style={getButtonSize(props.size).button}
                className='viewevent-btn'
                onClick={() => handleEventNavigation(props.event)}
                {...props}
            >
                <img style={getButtonSize(props.size).icon} alt='event page' src={plusSign} />
                {props.vieweventbtnlabel ? props.vieweventbtnlabel : 'View event'}
            </div>
        )
    )
}

export const HighlightedEventButtons = (props) => {
    const navigate = useNavigate();
    const [userState, dispatch] = React.useContext(UserContext)
    const [loginDialog, setLoginDialog] = useState(false)

    const handleEventNavigation = (event) => {
        addSuggestionToUser({ speakers: [], events: [event.id], topics: [], industry: [] })
        navigate(`event/${event.title.replace(/[;:,!*`?&/\\"<>|'\s]/g, '')}?id=${event.id}`, { state: { event: event } })
    }

    const handleEpisodeNavigation = (episode) => {
        addSuggestionToUser({ events: [props.event.id] })
        navigate(`episodes/${episode.title.replace(/[;:,!*`?&/\\"<>|'\s]/g, '')}?id=${episode.id}`, { state: { episode: episode, event: props.event } })
    }

    return (
        <>
            {props.event.private && !userState.logged ? (
                <>
                    <div
                        style={getButtonSize(props.size).button}
                        className='viewevent-btn'
                        onClick={() => setLoginDialog(true)}
                        {...props}
                    >
                        <img style={getButtonSize(props.size).icon} alt='login' src={plusSign} />
                        {props.loginbtnlabel ? props.loginbtnlabel : 'Log in to learn more'}
                    </div>
                </>
            ) : (
                <div className='combo-buttons-div'>
                    {(props.event.episodes[0]?.available && !props.event.episodes[0].private) &&
                        <div
                            style={getButtonSize(props.size).button}
                            className='watchnow-btn-higlighted'
                            onClick={() => handleEpisodeNavigation(props.event.episodes[0])}
                            {...props}
                        >
                            <img style={getButtonSize(props.size).icon} alt='play' src={playIcon} />
                            {props.watchnowbtnlabel ? props.watchnowbtnlabel : 'Watch now'}
                        </div>
                    }
                    <div
                        style={getButtonSize(props.size).button}
                        className='viewevent-btn'
                        onClick={() => handleEventNavigation(props.event)}
                        {...props}
                    >
                        <img style={getButtonSize(props.size).icon} alt='event page' src={plusSign} />
                        {props.vieweventbtnlabel ? props.vieweventbtnlabel : 'Learn more'}
                    </div>
                </div>
            )}
            <LoginSignupMainDialog open={loginDialog} close={() => setLoginDialog(false)} tabIndex={0} />
        </>
    )
}

export const ToExternalPage = (props) => {
    return (
        <div
            style={getButtonSize(props.size).button}
            className='viewevent-btn'
            onClick={() => openInNewTab(props.url)}
            {...props}
        >
            <img style={getButtonSize(props.size).icon} alt='event page' src={plusSign} />
            {props.children}
        </div>
    )
}

export const Custom = (props) => {
    return (
        <div
            style={getButtonSize(props.size).button}
            className='viewevent-btn'
            {...props}
        >
            <img style={getButtonSize(props.size).icon} alt='event page' src={plusSign} />
            {props.children}
        </div>
    )
}

export const PrivateButton = (props) => {
    return (
        <div
            style={getButtonSize(props.size).button}
            className='viewevent-btn'
            {...props}
        >
            <Lock />
            {props.children}
        </div>
    )
}
