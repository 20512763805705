import React, { useState, useEffect } from 'react'
import { UserContext } from '../components/contexts/User'
import { useNavigate } from 'react-router-dom'

import { Backdrop, Tooltip } from '@mui/material'
import { ThreeDots } from 'react-loading-icons'

import { ViewEvent, PrivateButton } from '../components/Buttons/PrefabButtons'
import MaybeYouLikeSection from '../components/MaybeYouLike/MaybeYouLikeSection'
import LoginSignupMainDialog from '../components/Login-signup/LoginSignupMainDialog'

import { trackView } from '../utils'
import { getUserName } from '../components/UserInfo/UserLog'

import Breadcrumb from '../components/Breadcrumbs/Breadcrumb'
import SpeakersCard from '../components/Events/SpeakersCard'
import moment from 'moment'
import PrivateInfoDialog from '../components/Dialogs/PrivateInfoDialog'
import UserAuth from '../components/Login-signup/UserAuth'
import { CalendarToday, Place, Translate } from '@mui/icons-material'

import './css/episode.css'
import { getEntity } from '../API/requests'

function Episode() {
    const [userState, dispatch] = React.useContext(UserContext)
    const [episode, setEpisode] = useState()
    const [loginDialog, setLoginDialog] = useState(false);
    const urlParams = new URLSearchParams(window.location.search)
    const navigate = useNavigate();
    const [openUserAuth, setOpenUserAuth] = useState(false)
    const [openPrivate, setOpenPrivate] = useState(false)
    const [userHasAccess, setUserHasAccess] = useState(false)

    const embedParams = () => {
        return `&defaultQuality=fullhd&showTray=1`
    }

    useEffect(() => {

        if (urlParams.get('id')) {
            getEntity('episodes', urlParams.get('id')).then(res => {
                setEpisode(res.data)
                trackView('episode', res.data.id, getUserName())
            }, err => {
                console.log(err)
                navigate('/episodes')
            })
        } else {
            navigate('/episodes')
        }

    }, [])

    useEffect(() => {
        if (episode) {
            if (!userState.logged && episode.private) {
                setLoginDialog(true)
            } else {
                setLoginDialog(false)
            }
        }
    }, [userState, episode])

    useEffect(() => {
        setUserHasAccess(userState.logged && userState.access)

        if (openUserAuth === true) {
            onViewPrivateInfo()
        }
    }, [userState])

    const onViewPrivateInfo = () => {

        if (userState.auth) {
            setOpenPrivate(true)
            setOpenUserAuth(false)
        } else {
            setOpenUserAuth(true)
        }
    }

    return (
        episode ? (
            <div className='episode-main-div'>
                <Breadcrumb serie={episode.event.series} event={episode.event} episode={episode} entity={'episode'} />
                {episode.video?.length > 0 ?
                    <div className='video-iframe'>
                        <iframe
                            src={episode.video + embedParams()}
                            style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
                            frameBorder="0"
                            title='episode-video-iframe'
                            border="0"
                            scrolling="no"
                            mozallowfullscreen="1"
                            webkitallowfullscreen="1"
                            allowFullScreen="1"
                            allow="autoplay; fullscreen">
                        </iframe>
                    </div>
                    :

                    <div className='video-iframe-no-video'></div>
                }
                <div className='episode-page-bottom-section'>
                    <div className='bottom-section-info'>
                        <div className='bottom-section-text'>
                            <p className='text-title'>{episode.title}</p>
                            <div className='event-page-amenities-div'>
                                <div className='sub-info-item'>
                                    <CalendarToday sx={{ scale: '0.9' }} />
                                    <p>{moment(episode.date).format('MMM DD, YYYY')}</p>
                                </div>
                                <Tooltip title='Episode Location' disableInteractive={true} placement='top'>
                                    <div className='sub-info-item'>
                                        <Place />
                                        <p>{episode.location?.length > 0 ? episode.location : 'Online'}</p>
                                    </div>
                                </Tooltip>
                                {episode.language &&
                                    <Tooltip title='Episode Language' disableInteractive={true} placement='top' >
                                        <div className='sub-info-item'>
                                            <Translate />
                                            <p>{episode.language}</p>
                                        </div>
                                    </Tooltip>
                                }
                            </div>
                            <div className='event-page-chips-div'>
                                <AmenitiesChip name='Industry' array={episode.industries} />
                                <AmenitiesChip name='Topic' array={episode.topics} />
                                <AmenitiesChip name='Format' array={episode.formats} />
                                <AmenitiesChip name='Type' array={episode.types} />
                            </div>

                            <p className='text-description'>{episode.description}</p>
                            <div className='event-page-actions-div'>
                                <ViewEvent size='fitting-medium' vieweventbtnlabel='Watch more episodes' event={episode.event} />
                                {userHasAccess &&
                                    <PrivateButton size='fitting-medium' onClick={onViewPrivateInfo}>View Private Info</PrivateButton>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='bottom-section-episode-speakers'>
                        <div className='episode-speakers-cards'>
                            {episode.speakers.map(speaker => {
                                return (
                                    <SpeakersCard key={speaker.id} speaker={speaker} />
                                )
                            })}
                        </div>
                    </div>
                </div>
                <MaybeYouLikeSection title='You may also like' />
                <LoginSignupMainDialog redirectOnClose={true} open={loginDialog} preventClose={true} close={() => setLoginDialog(false)} tabIndex={0} />
                {userHasAccess && <PrivateInfoDialog open={openPrivate} close={() => setOpenPrivate(false)} entity='episode' id={episode.id} />}
                <UserAuth open={openUserAuth} close={() => setOpenUserAuth(false)} />
            </div>
        ) : (
            <div>
                <Backdrop sx={{ color: '#fff' }} open={true}>
                    <ThreeDots fill='var(--orange)' />
                </Backdrop>
            </div>
        )
    )
}

export default Episode

const AmenitiesChip = ({ name, array }) => {
    if (array.length === 0) return ''
    return (
        <div className='event-list-chip' >
            <p>{name}:
                {array.map((el, index) => (
                    <span key={el.id} className='chip-span' >
                        {index === 0 ? ` ${el.name}` : `, ${el.name}`}
                    </span>
                ))}
            </p>
        </div>
    )
}