import React, { useState } from 'react'
import './EpisodeCard.css'
import { WatchNow, Combo } from '../Buttons/PrefabButtons'
import Tooltip from '@mui/material/Tooltip';
import { Info, Add } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { generateTumbnailImageFromVideoUrl } from '../../utils';


const CustomTooltip = ({ children, ...props }) => {
    return (
        <Tooltip
            title={props.title}
            arrow
            componentsProps={{
                tooltip: {
                    sx: {
                        marginTop: '0px !important',
                        backgroundColor: 'var(--purple)',
                        color: 'white',
                        width: 'fit-content',
                        minWidth: '312px',
                        height: 'fit-content',
                        border: 'none',
                    }
                }
            }}
            {...props}
        >
            {children}
        </Tooltip>
    )
}


function EpisodeCard(props) {
    const [showMobileDescription, setShowMobileDescription] = useState(false)

    const fontColor = props.whiteFont ? { color: 'white' } : { color: 'black' }

    const getToolTipContent = () => {
        return (
            <div className='toolTip-main-div'>
                {props.episode.speakers?.length > 0 &&
                    <div className='tooltip-element'>
                        <p>Speakers:</p>
                        <div className='speakers-tooltip-div'>
                            {props.episode.speakers.map(speaker => {
                                return (
                                    <Link
                                        key={speaker.name}
                                        className='tooltip-link-speaker'
                                        to='/episodes'
                                        state={{ state: { Speaker: { id: speaker.id, value: speaker.name } } }}
                                    >
                                        {speaker.name}
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                }
                {props.episode.event &&
                    <div className='tooltip-element'>
                        <p>Event:</p>
                        <Link
                            className='tooltip-link'
                            to='/episodes'
                            state={{ state: { Event: { id: props.episode.event.id, value: props.episode.event.title } } }}
                        >
                            {props.episode.event.title}
                        </Link>
                    </div>
                }
                {props.episode.topics?.length > 0 &&
                    <div className='tooltip-element'>
                        <p>Topic:</p>
                        {props.episode.topics.map(topic => {
                            return (
                                <Link
                                    key={topic.id}
                                    className='tooltip-link'
                                    to='/episodes'
                                    state={{ state: { Topic: { id: topic.id, value: topic.name } } }}
                                >
                                    {topic.name}
                                </Link>
                            )
                        })}
                    </div>
                }
                {props.episode.industries?.length > 0 &&
                    <div className='tooltip-element'>
                        <p>Industry:</p>
                        {props.episode.industries.map(indust => {
                            return (
                                <Link
                                    key={indust.id}
                                    className='tooltip-link'
                                    to='/episodes'
                                    state={{ state: { Industry: { id: indust.id, value: indust.name } } }}
                                >
                                    {indust.name}
                                </Link>
                            )
                        })}
                    </div>
                }
            </div>
        )
    }

    return (
        <div className='episode-card-main-div'>
            <ClickAwayListener onClickAway={() => setShowMobileDescription(false)}>
                <div className='episode-card-content'
                    style={props.episode.image ?
                        { backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/episodes/${props.episode.image})` } :
                        { backgroundImage: `url(${generateTumbnailImageFromVideoUrl(props.episode.video)})` }
                    }
                >
                    <div className={props.episode.available ? 'episode-card-content-gradient' : 'episode-card-content-pink-gradient'} />
                    <Info className={`episode-card-add-btn ${showMobileDescription ? 'hide' : ''}`} onClick={() => setShowMobileDescription(true)} />
                    <div className={`mobile-episode-description-div ${showMobileDescription ? 'show' : ''}`}>
                        <CustomTooltip title={getToolTipContent()} placement='bottom' >
                            <Add className='episode-card-info-btn' />
                        </CustomTooltip>
                        <p className='episode-description'>{props.episode.description}</p>
                    </div>
                    <div className={props.episode.available ? 'episode-card-content-black-div' : 'episode-card-content-pink-div'}>
                        <CustomTooltip title={getToolTipContent()} placement='bottom' >
                            <Add className='episode-card-info-btn' />
                        </CustomTooltip>
                        <p style={props.hideViewMoreBtn && !props.episode.available ? { height: '80%' } : { height: '50%' }} className='episode-description'>{props.episode.description}</p>
                    </div>
                    {!props.episode.available && <div className='episode-card-available-soon'>Available soon</div>}
                    <div className='episode-card-buttons'>
                        {props.hideViewMoreBtn ? (
                            <WatchNow episode={props.episode} event={props.episode.event} size='fitting-small' />
                        ) : (
                            <Combo episode={props.episode} event={props.episode.event} size='fitting-small' loginbtnlabel={props.episode.available ? 'Log in to view episode' : 'Log in to view event'} />
                        )}
                    </div>
                </div>
            </ClickAwayListener>
            <p className='episode-card-title' style={fontColor}>{props.episode.title}</p>
        </div>
    )
}

export default EpisodeCard;
