import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ScrollToTop } from './utils';
import { UserProvider } from './components/contexts/User';

import App from './App';

ReactDOM.render(
	<BrowserRouter>
		<UserProvider>
			<ScrollToTop />
			<Routes>
				<Route path='/*' element={<App />} />
			</Routes>
		</UserProvider>
	</BrowserRouter>,
	document.getElementById('root')
);


