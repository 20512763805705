import { useState, useEffect } from 'react';

function appendScript(baseUrl, setScriptLoaded, setScript) {
    if (window.MktoForms2) return setScriptLoaded(true);
    const script = document.createElement('script');
    script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
    script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
    document.body.appendChild(script);
}

export function useMarketo({ baseUrl, munchkinId, formId, cb }) {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    useEffect(() => {
        if (scriptLoaded) {
            window.MktoForms2.loadForm(baseUrl, munchkinId, formId, cb);
            return;
        }
        appendScript(baseUrl, setScriptLoaded);
    }, [scriptLoaded, baseUrl, munchkinId, formId, cb]);
}

export const loadMarketoScript = async (baseUrl) => {
    if (window.MktoForms2) return;
    const script = document.createElement('script');
    script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
    document.body.appendChild(script);
}
