import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, Divider, Chip, Avatar } from '@mui/material'
import { Close, Face, InsertLink, Public, CorporateFare, BookmarkAdded } from '@mui/icons-material'
import { getPrivateData } from '../../API/requests'

import './PrivateInfoDialog.css'
import { openInNewTab } from '../../utils'

function PrivateInfoDialog(props) {
    const [eventData, setEventData] = useState(null)
    const [episodeData, setEpisodeData] = useState(null)

    useEffect(() => {
        if (props.open) {
            getPrivateData(props.entity, props.id).then(res => {
                if (props.entity === 'event') {
                    setEventData(res.data)
                    setEpisodeData(res.data.episodes)
                } else {
                    setEpisodeData([res.data])
                }
            }, err => {
                console.error(err)
            })
        }
    }, [props])

    return (
        <Dialog
            fullScreen={window.innerWidth < 960}
            maxWidth='md'
            open={props.open}
            onClose={props.close}
        >
            <Close className='event-dialog-close-icon' onClick={props.close} />
            <DialogContent className='private-info-dialog-content' >
                {props.entity === 'event' ?
                    <>
                        {eventData &&
                            <div className='private-info-event-info'>
                                <p>Event:</p>
                                <EventInfoDisplay event={eventData} />
                            </div>
                        }
                        {episodeData?.length > 0 &&
                            <div className='private-info-episode-info'>
                                <p>Episodes:</p>
                                {episodeData.map(episode => <EpisodesInfoDisplay key={episode.id} episode={episode} />)}
                            </div>
                        }
                    </>
                    :
                    <div className='private-info-episode-info'>
                        {episodeData?.map(episode => <EpisodesInfoDisplay key={episode.id} episode={episode} />)}
                    </div>
                }
            </DialogContent>
        </Dialog>
    )
}

export default PrivateInfoDialog

const EpisodesInfoDisplay = ({ episode }) => {
    return (
        <div className='episode-display-div'>
            <p className='display-div-title'>{episode.title}</p>
            <Divider orientation='horizontal' sx={{ background: 'white' }} />
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Chip className='values-chip' icon={<Face />} label='Responsible' />
                {episode.responsible?.length > 0 ?
                    <div className='private-info-avatar-div'>
                        <Avatar alt={episode.responsible} src={episode.responsible.split(',')[1] !== 'null' ? `${process.env.REACT_APP_SERVER_URL}/resources/images/users/${episode.responsible.split(',')[1]}` : null} sx={{ width: '30px', height: '30px' }} />
                        <p>{episode.responsible.split(',')[0]}</p>
                    </div>
                    :
                    <p>N/D</p>
                }
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Chip className='values-chip' icon={<CorporateFare />} label='Organization' />
                <p>{episode.organization?.length > 0 ? episode.organization : 'N/D'}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Chip className='values-chip' icon={<Public />} label='Region' />
                <p>{episode.region?.length > 0 ? episode.region : 'N/D'}</p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Chip className='values-chip' icon={<InsertLink />} label='SalesForce URL' />
                {episode.salesforce_url?.length > 0 ?
                    <p className='private-info-url' onClick={() => openInNewTab(episode.salesforce_url)}>{episode.salesforce_url}</p>
                    :
                    <p>N/D</p>
                }
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Chip className='values-chip' icon={<BookmarkAdded />} label='Status' />
                <p>{episode.status?.length > 0 ? episode.status : 'N/D'}</p>
            </div>
        </div>
    )
}

const EventInfoDisplay = ({ event }) => {
    return (
        <div className='event-display-div'>
            <p className='display-div-title'>{event.title}</p>
            <Divider orientation='horizontal' sx={{ background: 'white' }} />
            {event.responsible?.length > 0 &&
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Chip className='values-chip' icon={<Face />} label='Responsible' />
                    <div className='private-info-avatar-div'>
                        <Avatar alt={event.responsible} src={event.responsible.split(',')[1] !== 'null' ? `${process.env.REACT_APP_SERVER_URL}/resources/images/users/${event.responsible.split(',')[1]}` : null} sx={{ width: '30px', height: '30px' }} />
                        <p>{event.responsible.split(',')[0]}</p>
                    </div>
                </div>
            }
            {event.organization?.length > 0 &&
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Chip className='values-chip' icon={<CorporateFare />} label='Organization' />
                    <p>{event.organization}</p>
                </div>
            }
            {event.region?.length > 0 &&
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Chip className='values-chip' icon={<Public />} label='Region' />
                    <p>{event.region}</p>
                </div>
            }
            {event.salesforce_url?.length > 0 &&
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Chip className='values-chip' icon={<InsertLink />} label='SalesForce URL' />
                    <p className='private-info-url' onClick={openInNewTab(event.salesforce_url)}>{event.salesforce_url}</p>
                </div>
            }
            {event.status?.length > 0 &&
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Chip className='values-chip' icon={<BookmarkAdded />} label='Status' />
                    <p>{event.status}</p>
                </div>
            }
        </div>
    )
}