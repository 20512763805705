import React, { useState, useEffect } from 'react'
import EpisodesFilter from '../components/Autocomplete/EpisodesFilter'
import { useLocation } from 'react-router-dom'
import sortArray from 'sort-array'

import EpisodeCard from '../components/Events/EpisodeCard'
import MaybeYouLikeSection from '../components/MaybeYouLike/MaybeYouLikeSection'
import { getEntity } from '../API/requests'
import { Backdrop } from '@mui/material'
import { ThreeDots } from 'react-loading-icons'

import './css/allEpisodes.css'


function AllEpisodes() {
    const { state } = useLocation()
    const [data, setData] = useState([])
    const [episodes, setEpisodes] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        getEntity('episodes').then(res => {
            setData(res.data)
            setEpisodes(res.data)
            setLoading(false)
        }, err => {
            setLoading(false)
            console.log(err)
        })
    }, [])

    return (
        <div className='allepisodes-main-div'>
            {!loading ? (
                <>
                    <EpisodesFilter data={[...episodes]} filteredData={state} updateParentState={(data) => setData(data)} />
                    {data.length > 0 ?
                        <div className='allepisodes-cards'>
                            {sortArray(data, { by: 'date', order: 'desc' }).map(episode => {
                                return (
                                    <EpisodeCard
                                        key={episode.id}
                                        episode={episode}
                                        whiteFont={false}
                                    />
                                )
                            })}
                        </div>
                        :
                        <p style={{ margin: '25px', textAlign: 'center' }}>No Results</p>
                    }
                    <div className='maybe-you-like-div'>
                        <MaybeYouLikeSection title='Maybe you like' />
                    </div>
                </>
            ) : (
                <div>
                    <Backdrop sx={{ color: '#fff' }} open={true}>
                        <ThreeDots fill='var(--orange)' />
                    </Backdrop>
                </div>
            )}
        </div>
    )
}

export default AllEpisodes
