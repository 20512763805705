import React, { useEffect, useState, useRef } from 'react'
import EventFilters from '../Autocomplete/EventFilters'
import moment from 'moment'
import { Place, CalendarToday, Translate } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
// import CalendarEventDialog from '../Dialogs/CalendarEventDialog'
import sortArray from 'sort-array'
import { useNavigate } from 'react-router-dom'
import { openInNewTab } from '../../utils'
import { ThreeDots } from 'react-loading-icons'

import './CalendarList.css'

function CalendarList(props) {
    const [events, setEvents] = useState([])
    const [immutableEvents, setImmutableEvents] = useState([])
    const [filteredData, setFilteredData] = useState(null)
    const [loading, setLoading] = useState(false)
    const itensContainerRef = useRef(null)
    const closestItemRef = useRef(null)

    useEffect(() => {
        if (props.events.length > 0) {
            setLoading(true)

            const mappedEventsDate = props.events.map(event => {
                if (moment(event.start_date).isBefore(moment())) {
                    return { ...event, sortedDate: event.end_date }
                } else {
                    return { ...event, sortedDate: event.start_date }
                }
            })

            const sortedEvents = sortArray(mappedEventsDate, { by: 'sortedDate', order: 'asc' })

            const onlyFutureDates = sortedEvents.filter(event => moment(event.end_date).isSameOrAfter(moment(), 'day'))
            if (onlyFutureDates.length === 0) {
                setEvents(sortedEvents)
                setImmutableEvents(sortedEvents)
                setLoading(false)
                return;
            }

            const closestEvent = onlyFutureDates.find(event => {
                const diff = moment(event.end_date).diff(moment(), 'days');
                return diff >= 0
            })

            const closestEventIndex = sortedEvents.findIndex(event => event.id === closestEvent.id)
            sortedEvents[closestEventIndex].focus = true;

            const getUniqueBy = (arr, prop) => {
                const set = new Set();
                return arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));
            };

            const mappedSortedEvents = sortedEvents.map(event => {
                let topics = []
                let industries = []
                let formats = []
                let types = []

                if (event.episodes.length > 0) {
                    event.episodes.forEach(el => {
                        topics = [...new Set([...topics, ...el.topics])]
                        industries = [...new Set([...industries, ...el.industries])]
                        formats = [...new Set([...formats, ...el.formats])]
                        types = [...new Set([...types, ...el.types])]
                    });

                    return {
                        ...event,
                        topics: getUniqueBy(topics, 'id'),
                        industries: getUniqueBy(industries, 'id'),
                        formats: getUniqueBy(formats, 'id'),
                        types: getUniqueBy(types, 'id'),
                    }
                }
                return event
            })
            setEvents(mappedSortedEvents)
            setImmutableEvents(mappedSortedEvents)
            setLoading(false)

        }
    }, [props.events])

    useEffect(() => {
        if (events.length > 0) {
            if (closestItemRef.current && itensContainerRef.current) {
                const topPos = closestItemRef.current.offsetTop;
                if (window.innerWidth > 900) {
                    itensContainerRef.current.scrollTop = topPos - 220;
                } else {
                    itensContainerRef.current.scrollTop = topPos;
                }
            }
        }
    }, [events])

    return (
        <div className='calendar-list-main-div'>
            <EventFilters data={immutableEvents} filteredData={filteredData} updateParentState={(data) => setEvents(data)} />
            {props.loading || loading ?
                <div>
                    <ThreeDots fill='var(--orange)' />
                    <p>Loading events...</p>
                </div>
                :
                <div className='calendar-list-container' ref={itensContainerRef}>
                    {events.length === 0 ?
                        <p>No results</p>
                        :
                        events.map(event => (
                            <div key={event.id} ref={event.focus && closestItemRef} style={{ width: '100%' }}>
                                <EventDisplay event={event} setFilteredData={setFilteredData} />
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default CalendarList

const EventDisplay = ({ event, setFilteredData }) => {
    // const [openDialog, setOpenDialog] = useState(false)
    const navigate = useNavigate()

    const onChipClick = (value, property) => {
        setFilteredData({ property, value })
    }

    const handleTitleClick = () => {
        if (event.event_page) {
            openInNewTab(event.event_page);
            return;
        }
        navigate(`/event/${event.title.replace(/[;:,!*`?&/\\"<>|'\s]/g, '')}?id=${event.id}`, { state: { event: event } })
    }

    const getDates = () => {
        if (moment(event.start_date).isSame(event.end_date, 'day')) {
            return moment(event.start_date).format('MMM DD, YYYY')
        }
        return `${moment(event.start_date).format('MMM DD, YYYY')} - ${moment(event.end_date).format('MMM DD, YYYY')}`

    }

    return (
        <div className='event-list-div'>
            <img alt={event.title} src={`${process.env.REACT_APP_SERVER_URL}/resources/images/events/${event.landscape_image}`} />
            <div className='event-list-info'>
                <p className='event-list-title' onClick={handleTitleClick}>{event.title}</p>
                <div className='event-list-sub-info'>
                    {event.series &&
                        <Tooltip disableInteractive title='Click to view this serie'>
                            <p onClick={() => navigate(`/serie/${event.series.title.replace(/[;:,!*`?&/\\"<>|'\s]/g, '')}?id=${event.series.id}`)} className='event-serie-link'>{event.series.title}</p>
                        </Tooltip>
                    }
                    <div className='sub-info-item'>
                        <CalendarToday sx={{ scale: '0.9' }} />
                        <p>{getDates()}</p>
                    </div>
                    <div className='sub-info-item'>
                        <Place />
                        <p>{event.location?.length > 0 ? event.location : 'Online'}</p>
                    </div>
                    {event.language &&
                        <Tooltip title='Event Language' disableInteractive={true} >
                            <div className='sub-info-item'>
                                <Translate />
                                <p>{event.language}</p>
                            </div>
                        </Tooltip>
                    }
                </div>
                <div className='event-list-chips-div'>
                    <EventChip name='Format' array={event.formats} onClick={onChipClick} />
                    <EventChip name='Type' array={event.types} onClick={onChipClick} />
                    <EventChip name='Industry' array={event.industries} onClick={onChipClick} />
                    <EventChip name='Topic' array={event.topics} onClick={onChipClick} />
                </div>
            </div>
        </div >
    )
}

const EventChip = ({ name, array, onClick }) => {
    if (array.length === 0) return ''
    const multiple = array.length > 1

    return (
        window.innerWidth < 960 ?
            <div className='event-list-chip' >
                <p>{name}:
                    {array.map((el, index) => (
                        <Tooltip disableInteractive key={el.id} title={`Filter ${name} by ${el.name}`}>
                            <span className='chip-span' onClick={() => onClick({ id: el.id, name: el.name }, name)} >
                                {index === 0 ? ` ${el.name}` : `, ${el.name}`}
                            </span>
                        </Tooltip>
                    ))}
                </p>
            </div>
            :
            <div className='event-list-chip' >
                <p>{name}:
                    <Tooltip disableInteractive key={array[0].id} title={`Filter ${name} by ${array[0].name}`}>
                        <span className='chip-span' onClick={() => onClick({ id: array[0].id, name: array[0].name }, name)} >
                            {` ${array[0].name}`}
                        </span>
                    </Tooltip>
                    {multiple &&
                        <Tooltip arrow title={
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {array.map((el) => (
                                    <Tooltip disableInteractive key={el.id} title={`Filter ${name} by ${el.name}`}>
                                        <span className='chip-span' key={el.id} onClick={() => onClick({ id: el.id, name: el.name }, name)} >
                                            {`${el.name}\n`}
                                        </span>
                                    </Tooltip>
                                ))}
                            </div>
                        }>
                            <span >
                                , +{array.length - 1}
                            </span>
                        </Tooltip>
                    }
                </p>
            </div>
    )
}