import React from 'react';
import { Dialog, DialogContent } from '@mui/material'
import checkIcon from '../../assets/icons/bigcheck.svg'
import bigX from '../../assets/icons/bigX.svg'

import './Confirmation.css'

function Confirmation(props) {
    const { error, title, text, buttonLabel } = props
    
    return (
        <div>
            <Dialog
                maxWidth='md'
                open={props.open}
                onClose={props.close}
                className='confirmation-dialog'
            >
                <DialogContent className='confirmation-dialog-content'>
                    <div className='confirmation-dialog-inner-content'>
                        <img className='confirmation-icon' alt='success' src={error ? bigX : checkIcon} height='100px' width='100px' />
                        <p className='confirmation-title'>{title}</p>
                        <p className='confirmation-subtitle'>{text}</p>
                        {buttonLabel ? (
                            <div className='confirmation-btn' onClick={props.close}>{buttonLabel}</div>
                        ) : (
                            <div className='confirmation-btn' onClick={props.close}>OK</div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default Confirmation;
