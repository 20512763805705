import React from 'react'
import { Breadcrumbs } from '@mui/material'
import './Breadcrumb.css'
import { Link } from 'react-router-dom'

function Breadcrumb(props) {
    return (
        <div className='breadcrumb-main-div'>
            <Breadcrumbs aria-label='breadcrumb' separator={<p>/</p>}>
                {props.serie &&
                    <Link
                        className={`breadcrumb-link ${props.entity === 'serie' ? 'active' : ''}`}
                        to={`/serie/${props.serie.title.replace(/[;:,!*`?&/\\"<>|'\s]/g, '')}?id=${props.serie.id}`}
                    >
                        {props.serie.title}
                    </Link>
                }
                {props.event &&
                    <Link
                    className={`breadcrumb-link ${props.entity === 'event' ? 'active' : ''}`}
                        to={`/event/${props.event.title.replace(/[;:,!*`?&/\\"<>|'\s]/g, '')}?id=${props.event.id}`}
                    >
                        {props.event.title}
                    </Link>
                }
                {props.episode &&
                    <p className={`breadcrumb-link ${props.entity === 'episode' ? 'active' : ''}`}>{props.episode.title}</p>
                }
            </Breadcrumbs>
        </div>
    )
}

export default Breadcrumb