import React, { useEffect } from 'react'
import { UserContext } from '../contexts/User'
import { marketoVerify, verifyUser } from '../../API/requests'

export const getUserName = () => {
    const userName = sessionStorage.getItem('user') || localStorage.getItem('user')
    return userName ? userName : 'Guest'
}

export const useCheckUser = async () => {
    const [userState, dispatch] = React.useContext(UserContext);

    useEffect(() => {
        if (localStorage.getItem('remember_token') && localStorage.getItem('user_id')) {
            verifyUser(localStorage.getItem('remember_token'), localStorage.getItem('user_id')).then(res => {
                dispatch({ type: 'auth_user' })
            }, err => {
                localStorage.removeItem('user_id');
                localStorage.removeItem('remember_token');
                console.log(err)
                dispatch({ type: 'unauth_user' })
            })
        }

        if (sessionStorage.getItem('user')) dispatch({ type: 'login_user' });
        else getUser(dispatch, userState)
    }, [])
}

export const getUser = async (dispatch, userState) => {
    if (!userState.logged) {
        if (localStorage.getItem('user') && localStorage.getItem('token')) {
            const email = localStorage.getItem('email') || sessionStorage.getItem('email')

            marketoVerify({ name: localStorage.getItem('user'), token: localStorage.getItem('token'), email }).then(res => {
                dispatch({ type: 'login_user' });
                if (res.data.access) {
                    dispatch({ type: 'access_user' });
                }
            }, err => {
                console.log(err)
                logoutUser()
                dispatch({ type: 'logout_user' });
            })
        }
    }
}

export const loginUser = (userName, token, email, remember) => {
    if (remember) {
        localStorage.setItem('user', userName);
        localStorage.setItem('token', token)
        localStorage.setItem('email', email)
    } else {
        sessionStorage.setItem('user', userName);
        sessionStorage.setItem('email', email);
    }
}

export const logoutUser = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('remember_token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('email');
}

