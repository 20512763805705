import React, { useState, useRef, useEffect } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { RightArrow, LeftArrow } from '../horizontalScroll/Arrows'
import sortArray from 'sort-array'
import EventCard from '../Events/EventCard'

import useDrag from '../horizontalScroll/useDrag'

import './EventsContainer.css'

function EventsContainer(props) {
    const { dragStart, dragStop, handleDrag } = useDrag();
    const [eventCardWindowFull, setEventCardWindowViewFull] = useState(false);
    const [allEvents, setAllEvents] = useState([])

    const scrollContainerRef = useRef();
    const cardContainerRef = useRef();

    useEffect(() => {
        setAllEvents(props.data.filter(event => event.available && event.episodes.length > 0))
    }, [props])


    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            window.requestAnimationFrame(() => { //Prevents Webpack error
                if (allEvents) {
                    setEventCardWindowViewFull(340 * allEvents.length > entries[0].contentRect.width);
                }
            });
        })

        const container = cardContainerRef.current
        if (container) observer.observe(container);
        return () => { if (container) observer.unobserve(container) }
    }, [cardContainerRef, allEvents])


    return (
        <div className='events-home-container-div' ref={cardContainerRef}>
            <div className='events-home-container-div-title'>
                <p>Teleperformance Events</p>
            </div>
            <div
                className='events-home-container-div-cards'
                onMouseLeave={dragStop}
                style={!eventCardWindowFull ? { display: 'flex', justifyContent: 'center' } : {}}
            >
                {allEvents.length === 0 ? (
                    <p style={{ color: 'white', fontSize: '26px' }}>no events to display</p>
                ) : (
                    <ScrollMenu
                        apiRef={scrollContainerRef}
                        LeftArrow={LeftArrow}
                        RightArrow={RightArrow}
                        onMouseDown={() => dragStart}
                        onMouseUp={() => dragStop}
                        onMouseMove={handleDrag}
                        itemClassName='scroll-container-event-item'
                    >
                        {sortArray(allEvents, { by: 'start_date', order: 'desc' }).map(event => {
                            return (
                                <EventCard key={event.id} event={event} />
                            )
                        })}
                    </ScrollMenu>
                )}
            </div>
        </div>
    )
}

export default EventsContainer