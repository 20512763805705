export const addSuggestionToUser = (data, type) => {
    if (localStorage.getItem('userSuggestions')) {
        const localStorageData = JSON.parse(localStorage.getItem('userSuggestions'))

        Object.keys(data).forEach(key => {
            if(data[key].length === 0) return;

            const filteredData = data[key].filter(element => !localStorageData[key].includes(element))
            
            if(filteredData.length === 0) return;

            if (localStorageData[key].length < (4 - filteredData.length)) {
                filteredData.forEach(elem => {
                    localStorageData[key].push(elem)
                })
            } else {
                filteredData.forEach(elem => {
                    localStorageData[key].shift();
                    localStorageData[key].push(elem);
                })
            }
            localStorage.setItem('userSuggestions', JSON.stringify(localStorageData))
        })

    } else {
        localStorage.setItem('userSuggestions', JSON.stringify(data))
    }
}

export const getUserSuggestions = () => {
    return JSON.parse(localStorage.getItem('userSuggestions'))
}

