import './App.css';
import { useState } from 'react';
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import Help from './pages/Help';
import Event from './pages/Event';
import Episode from './pages/Episode';
import CalendarPage from './pages/CalendarPage';
import AllEpisodes from './pages/AllEpisodes';
import About from './pages/About';
import NotFound from './pages/NotFound'
import Serie from './pages/Serie'
import Navbar from './components/NavBar-Footer/Navbar'
import Footer from './components/NavBar-Footer/Footer'
import CookiesPopUp from './components/Dialogs/CookiesPopUp';
import { loadMarketoScript } from './marketoForms/useMarketo'
import { useCheckUser } from './components/UserInfo/UserLog'
import { Backdrop } from '@mui/material';
import { ThreeDots } from 'react-loading-icons';
import usePageTracking from './usePageTracking';

import Logo from './assets/navbar/LEADER_white.svg'

function App() {
	const [ready, setReady] = useState(false)
	usePageTracking()

	useCheckUser().then(() => {
		loadMarketoScript('//app-sjp.marketo.com').then(() => {
			setReady(true)
		})
	})

	return (
		ready ? (
			<div>
				<Navbar />
				<CookiesPopUp />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/episodes' element={<AllEpisodes />} />
					<Route path='/event/:name' element={<Event />} />
					<Route path='/serie/:name' element={<Serie />} />
					<Route path='/episodes/:name' element={<Episode />} />
					<Route path='/calendar' element={<CalendarPage />} />
					{/* <Route path='/help' element={<Help />} /> */}
					<Route path='/about' element={<About />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
				<Footer />
			</div>
		) : (
			<div>
				<Backdrop sx={{ backgroundColor: 'var(--purple)' }} open={true}>
					<img alt='Leader insights logo' src={Logo} width='300px' height='auto' style={{ marginRight: '20px'}} />
					<ThreeDots fill='var(--orange)' />
				</Backdrop>
			</div>
		)
	);
}

export default App;
