import React, { useRef, useState, useEffect } from 'react'
import { Divider, Chip } from '@mui/material'
import { DropDownFilter } from './DropDownFilter';
import moment from 'moment';
import { getFilterData } from '../../API/requests';

import './EpisodesFilter.css'
import { FilterAlt } from '@mui/icons-material';

function EpisodesFilter(props) {
    const speakersFilterRef = useRef();
    const eventsFilterRef = useRef();
    const topicsFilterRef = useRef();
    const industryFilterRef = useRef();
    const yearFilterRef = useRef();
    const [speakersFilter, setSpeakersFilter] = useState([])
    const [eventsFilter, setEventsFilter] = useState([])
    const [topicsFilter, setTopicsFilter] = useState([])
    const [industryFilter, setIndustryFilter] = useState([])
    const [yearFilter, setYearFilter] = useState([])
    const [speakersList, setSpeakersList] = useState([])
    const [topicsList, setTopicsList] = useState([])
    const [industriesList, setIndustryList] = useState([])
    const [eventsList, setEventsList] = useState([])
    const [yearsList, setYearsList] = useState([])

    const [showFilter, setShowFilter] = useState(false)

    useEffect(() => {
        getFilterData('episodes').then(res => {
            setEventsList(res.data.events)
            setIndustryList(res.data.industries)
            setTopicsList(res.data.topics)
            setSpeakersList(res.data.speakers)

            const eventsYears = res.data.events.map(a => moment(a.start_date).year().toString());
            setYearsList([...new Set([].concat(...eventsYears))].sort((a, b) => a - b))

        }, err => {
            console.log(err)
        })

    }, [])

    const filterResults = (value, field) => {
        switch (field) {
            case 'Speaker': setSpeakersFilter(value);
                break;
            case 'Event': setEventsFilter(value);
                break;
            case 'Topic': setTopicsFilter(value);
                break;
            case 'Industry': setIndustryFilter(value);
                break;
            case 'Year': setYearFilter(value);
                break;
            default:
                break;
        }
    }

    const filterLogicEpisodes = () => {

        if (!speakersFilter.length && !eventsFilter.length && !topicsFilter.length && !industryFilter.length && !yearFilter.length) {
            props.updateParentState(props.data);
            return;
        };

        const result = props.data.filter(episode => (
            speakersFilter.some(filteredSpeaker => episode.speakers.find(speaker => speaker.id === filteredSpeaker.id)) ||
            eventsFilter.some(filteredEvent => episode.event.id === filteredEvent.id) ||
            topicsFilter.some(filteredTopic => episode.topics.find(topic => topic.id === filteredTopic.id)) ||
            industryFilter.some(filteredIndus => episode.industries.find(ind => ind.id === filteredIndus.id)) ||
            yearFilter.some(year => parseInt(year.value) === moment(episode.date).year())

        ))

        props.updateParentState(result)
    }

    const deleteFilterEntries = (value, field) => {
        switch (field) {
            case 'speakers': speakersFilterRef.current.deleteOnChild(value);
                break;
            case 'event': eventsFilterRef.current.deleteOnChild(value);
                break;
            case 'topic': topicsFilterRef.current.deleteOnChild(value);
                break;
            case 'industry': industryFilterRef.current.deleteOnChild(value);
                break;
            case 'year': yearFilterRef.current.deleteOnChild(value);
                break;
            default:
                break;
        }
    }

    const removeAllFromChild = () => {
        speakersFilterRef.current.removeAll();
        eventsFilterRef.current.removeAll();
        topicsFilterRef.current.removeAll();
        industryFilterRef.current.removeAll();
        yearFilterRef.current.removeAll()
    }

    const checkIfHasDivider = (field) => {
        switch (field) {
            case 'speakers': return speakersFilter.length && (eventsFilter.length || topicsFilter.length || industryFilter.length);
            case 'events': return eventsFilter.length && (topicsFilter.length || industryFilter.length);
            case 'topics': return topicsFilter.length && industryFilter.length;
            default: return false
        }
    }

    useEffect(() => {
        if (props.filteredData) {
            const propertyName = Object.keys(props.filteredData.state)[0]
            filterResults([props.filteredData.state[propertyName]], propertyName)
        }
    }, [props.filteredData])

    useEffect(() => {
        filterLogicEpisodes();
    }, [speakersFilter, eventsFilter, topicsFilter, industryFilter, yearFilter])

    return (
        <div className={`standard-filter-main-div ${showFilter ? 'show' : 'hide'}`}>
            <div className='standard-filters'>
                {topicsList && industriesList && speakersList ? (
                    <>
                        <DropDownFilter ref={topicsFilterRef} filter={filterResults} data={topicsList} label='Topic' placeholder='Topic' isDataObject={true} />
                        <DropDownFilter ref={industryFilterRef} filter={filterResults} data={industriesList} label='Industry' placeholder='Industry' isDataObject={true} />
                        <DropDownFilter ref={yearFilterRef} filter={filterResults} data={yearsList} label='Year' placeholder='Year' isDataObject={false} />
                        <DropDownFilter ref={eventsFilterRef} filter={filterResults} data={eventsList} label='Event' placeholder='Event' isDataObject={true} />
                        <DropDownFilter ref={speakersFilterRef} filter={filterResults} data={speakersList} label='Speaker' placeholder='Speakers' isDataObject={true} />
                    </>
                ) : ''}
            </div>
            <div className='standard-filtered-chips'>
                <div className='standard-all-chips'>
                    {topicsFilter.map(topic => {
                        return (
                            <Chip key={topic.id} color='secondary' variant='outlined' label={topic.value} onDelete={() => deleteFilterEntries(topic, 'topic')} />
                        )
                    })}
                    {checkIfHasDivider('topics') ? <Divider orientation='vertical' /> : ''}
                    {industryFilter.map(industry => {
                        return (
                            <Chip key={industry.id} color='secondary' variant='outlined' label={industry.value} onDelete={() => deleteFilterEntries(industry, 'industry')} />
                        )
                    })}
                    {yearFilter.map(year => {
                        return (
                            <Chip key={year.id} color='secondary' variant='outlined' label={year.value} onDelete={() => deleteFilterEntries(year, 'year')} />
                        )
                    })}
                    {checkIfHasDivider('events') ? <Divider orientation='vertical' /> : ''}
                    {eventsFilter.map(event => {
                        return (
                            <Chip key={event.id} color='secondary' variant='outlined' label={event.value} onDelete={() => deleteFilterEntries(event, 'event')} />
                        )
                    })}
                    {checkIfHasDivider('events') ? <Divider orientation='vertical' /> : ''}
                    {speakersFilter.map(speaker => {
                        return (
                            <Chip key={speaker.id} color='secondary' variant='outlined' label={speaker.value} onDelete={() => deleteFilterEntries(speaker, 'speakers')} />
                        )
                    })}
                    {checkIfHasDivider('speakers') ? <Divider orientation='vertical' /> : ''}
                    <div className='standard-clear-all-chip'>
                        {speakersFilter.length || eventsFilter.length || topicsFilter.length || industryFilter.length || yearFilter.length ? (
                            <Chip label='Clear All' onDelete={removeAllFromChild} />
                        ) : ''}
                    </div>
                </div>
            </div>
            <div className={`filters-show-button${showFilter ? ' active' : ''}`}>
                <FilterAlt />
                <p onClick={() => setShowFilter(!showFilter)}>{`${showFilter ? 'Hide' : 'Show'} Filters`}</p>
            </div>
        </div>
    )
}

export default EpisodesFilter
