import React from 'react'
import { HighlightedEventButtons } from '../Buttons/PrefabButtons'

function HighlightedContainer(props) {
    return (
        <>
            {props.event ? (
                props.event.trailer.length > 0 ? (
                    <div className='video-iframe-trailer test'>
                        <div className='top-video-black-div' style={{ minHeight: '200px' }}></div>
                        <div className='top-video-static-black-div'></div>
                        <div className='top-video-info'>
                            <p className='top-video-title'>{props.event.title}</p>
                            <p className='top-video-subtitle'>{props.event.subtitle}</p>
                            <div className='top-video-buttons'>
                                <HighlightedEventButtons event={props.event} size='fitting-medium' />
                            </div>
                        </div>
                        <iframe
                            src={`${props.event.trailer}&defaultQuality=fullhd&ambient=1`}
                            title='highlighted-event-iframe'
                            className='iframe test'
                            frameBorder="0"
                            border="0"
                            mozallowfullscreen="0"
                            webkitallowfullscreen="0"
                            allowFullScreen="0"
                            allow="autoplay">
                        </iframe>
                    </div>
                ) : (
                    <div className='home-top-video-div'>
                        <div className='top-video-black-div' style={{ minHeight: '275px' }}></div>
                        <div className='top-video-static-black-div'></div>
                        <img alt='promoted-event' className='top-image' src={`${process.env.REACT_APP_SERVER_URL}/resources/images/events/${props.event.landscape_image}`} />
                        <div className='top-video-info'>
                            <p className='top-video-title'>{props.event.title}</p>
                            <p className='top-video-subtitle'>{props.event.subtitle}</p>
                            <div className='top-video-buttons'>
                                <HighlightedEventButtons event={props.event} size='fitting-medium' />
                            </div>
                        </div>
                    </div>
                )
            ) : (
                ''
            )}
        </>
    )
}

export default HighlightedContainer