import React from 'react'
import { ViewEvent } from '../Buttons/PrefabButtons'
import { UserContext } from '../contexts/User'

import './EventCard.css'

function EventCard(props) {
    const [userState, dispatch] = React.useContext(UserContext)

    return (
        <div className='event-card-main-div' style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL}/resources/images/events/${props.event.portrait_image})` }}>
            <div className={(props.event.private || !props.event.available) && !userState.logged ? 'event-card-pink-div' : 'event-card-black-div'} />
            {props.event.available ? (
                props.event.private && !userState.logged ? (
                    <div className='event-card-private'>Log in to watch</div>
                ) : (
                    <div className='event-card-public'></div>
                )
            ) : (
                <div className='event-card-private'>Available Soon</div>
            )}
            <div className='event-card-info'>
                <p className='event-card-info-title'>{props.event.title}</p>
                <p style={{ fontSize: '14px', fontWeight: '400', color: 'white' }}>{props.event.subtitle}</p>
            </div>
            <div className='event-card-button'>
                {!props.event.available ? '' : (
                    <ViewEvent event={props.event} size='fitting-small' />
                )}
            </div>
        </div>
    )
}

export default EventCard
