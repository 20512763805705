import React, { useState, useEffect } from 'react'

import './LoginSignupMainDialog.css'

import { Dialog, DialogContent, Tabs, Tab, Box, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

import LoginDialog from './LoginDialog'
import SignupDialog from './SignupDialog'
import { useNavigate } from 'react-router-dom'

const isMobile = () => {
    return window.innerWidth < 900;
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} {...other}>
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    )
}

const StyledTabs = styled((props) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#B14282',
    },
});

const StyledTab = styled(props => <Tab disableRipple {...props} />)(
    () => ({
        textTransform: 'none',
        color: 'rgba(0, 0, 0, 0.8)',
        fontSize: '18px',
        '&:hover': {
            color: '#E8954E',
        },
        '&.Mui-selected': {
            color: '#B14282',
            fontWeight: 600,
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
)

function LoginSignupMainDialog(props) {
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        setTabValue(props.tabIndex);
        
    }, [props.tabIndex])

    const handleClose = () => {
        if(props.preventClose) return;
        props.close()
    }

    const handleCloseButton = () => {
        if(props.redirectOnClose) navigate(-1);
        props.close()
    }

    return (
        <div>
            <Dialog
                fullScreen={isMobile() ? true : false}
                maxWidth='md'
                disableEscapeKeyDown={props.preventClose}
                open={props.open}
                onClose={handleClose}
                className='login-dialog'
            >
                {isMobile() || props.preventClose ? <CloseIcon className='close-icon' onClick={handleCloseButton} /> : ''}
                <DialogContent className='login-dialog-content'>
                    <StyledTabs centered value={tabValue} onChange={(ev, value) => setTabValue(value)}>
                        <StyledTab label='Log in' id='tab0' />
                        <Tab id='tab1' label='' sx={{ margin: '0' }} icon={<Divider orientation='vertical' />} disabled />
                        <StyledTab label='Sign Up' id='tab2' />
                    </StyledTabs>
                    <TabPanel value={tabValue} index={0} >
                        <LoginDialog changetab={() => setTabValue(2)} close={props.close} />
                    </TabPanel>
                    <TabPanel  value={tabValue} index={2} >
                        <SignupDialog changetab={() => setTabValue(0)} close={props.close} />
                    </TabPanel>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default LoginSignupMainDialog
