import React, { useState } from 'react'
import { MuiOtpInput } from 'mui-one-time-password-input'

import './OTPInput.css'

function OTPInput(props) {
    const [otp, setOtp] = useState('')

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    const onComplete = value => {
        props.handleOnComplete(value)
        setOtp('')
    }

    return (
        <MuiOtpInput value={otp} onChange={handleChange} className='user-auth-otp' onComplete={onComplete} {...props} />
    )
}

export default OTPInput