import React, { useEffect, useState } from 'react'
import { ThreeDots } from 'react-loading-icons'
import { UserContext } from '../contexts/User'
import Confirmation from '../Dialogs/Confirmation';
import { loginUser } from '../UserInfo/UserLog'
import './SignupDialog.css'
import { loginUserMarketo } from '../../API/requests';

const dialogText = `We will give you access as soon as possible. Look out for an email from us!  

In the meantime, catch up with unlocked episodes.`

function SignupDialog(props) {
    const [loadingScriptError, setLoadingScriptError] = useState(false)
    const [showLoad, setShowLoad] = useState(true)
    const [userState, dispatch] = React.useContext(UserContext)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [confirmationDialogData, setConfirmationDialogData] = useState({ error: false, title: '', text: '', buttonLabel: 'OK' })
    const [waitText, setWaitText] = useState('Please wait')

    const formCallback = (form) => {
        form.onSuccess((values, followUpUrl) => {
            checkUserAfterSignup(values.Email)
            console.log(values)
            return false;
        })

        window.MktoForms2.whenReady(() => setShowLoad(false))
    }

    useEffect(() => {
        if (!window.MktoForms2) {
            return setLoadingScriptError(true);
        }
        window.MktoForms2.loadForm('//app-sjp.marketo.com', '195-OTS-308', '1451', formCallback);
    }, [])


    const checkUserAfterSignup = (email) => {
        setShowLoad(true)
        setWaitText('Give us a second please. Thank you for your patience!')
        setTimeout(async () => {
            loginUserMarketo(email).then(res => {
                loginUser(`${res.data.name}`, res.data.token, true);
                dispatch({ type: 'login_user' });
                setShowLoad(false)
                props.close()
            }, err => {
                setConfirmationDialogData({ error: false, title: `Thank you`, text: dialogText })
                setShowConfirmation(true)
                setShowLoad(false)
            })
        }, 5000)
    }

    const handleConfirmationDialogclose = () => {
        props.close()
    }

    return (
        loadingScriptError ? (
            <p className='marketo-form-loading-error-message' >
                {`Failed to load form

                 Please check your browser extentions, some might be blocking the form to load, look for 'app-sjp.marketo.com' on your blocked links`}
            </p>
        ) : (
            <div className="signup-form-main-div" >
                <form id={`mktoForm_1451`} className='signup-marketo-form' style={showLoad ? { zIndex: '-1' } : {}} />
                {showLoad &&
                    <div className='threedots-div'>
                        <ThreeDots fill='var(--pink)' />
                        <p style={{ marginTop: '15px', textAlign: 'center' }}>{waitText}</p>
                    </div>
                }
                {/* <button style={{ position: 'absolute' }} onClick={() => checkUserAfterSignup('rkahlich@pscu.com')}>fake sign up</button> */}
                <Confirmation {...confirmationDialogData} open={showConfirmation} close={handleConfirmationDialogclose} />
            </div>
        )
    );
}

export default SignupDialog
